import React from 'react'
import { Box,Typography } from '@mui/material';
import Typewriter from 'typewriter-effect';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  coloredSpan: {
    color: 'red', // Set your desired color
  },
}));

export default function TypeWriterText() {
  const classes = useStyles();
  return (
    <Typography sx={{textAlign:'center', fontFamily:'poppins', lineHeight:'54px', fontSize: {xs:'25px',sm:'30px',md:'36px'}, fontWeight:'700',  color: '#31284C'}}>Streamline your Business
      <span style={{color:'#2A97D0'}}>
      <Typewriter
      
      options={{
        autoStart:true,
        loop: true,
        skipAddStyles:true
      }}
    onInit={(typewriter) => {
      typewriter.typeString(`<span>Efficiently</span>`)
        .pauseFor(2000)
        .deleteAll()
        .typeString('<span>Effortlessly</span>')
        .pauseFor(2000)
        .deleteAll()
        .start();
    }}
  /></span>
     with All-in-one Software</Typography>
  )
}
