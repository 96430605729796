import React from 'react'
import { Box, Button, IconButton, Typography, Drawer } from '@mui/material';
import PricingHeader from '../components/pricing/PricingHeader';
import PriceSummary from '../components/pricing/PriceSummary';
import { motion, AnimatePresence } from 'framer-motion';
import bg from "../Assets/home/bg7.jpg"

export default function Pricing() {
  return (
    <motion.Box
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    >
    <Box sx={{backgroundImage: `url(${bg})`, backgroundPosition:'center', backgroundSize:'cover', pb:10}}>
        <PricingHeader />
        <PriceSummary />
    </Box>
    </motion.Box>
  )
}
