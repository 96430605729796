import React, {useState, useEffect} from 'react'
import { motion, useAnimation  } from 'framer-motion';
import { Box, Button, Typography, } from '@mui/material';
import { useInView } from "react-intersection-observer";
import HomeIcon3 from '../../Assets/home/homeicon3.png';
import HomeBg from "../../Assets/home/HomeBackground.png";
import RotateHome from "../../Assets/home/rotateHome.png";
import HomeMobile1 from "../../Assets/home/HomeMobile1.png";
import keyboardIIcon from "../../Assets/home/keyboardIIcon.png";
import keyboardIcon2 from "../../Assets/home/keyboardIcon2.png";

export default function AnimationCard() {

    const boxVariant = {
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
        hidden: { opacity: 0, scale: 0}
    };
      
    const cardVariant = {
        visible: { opacity: 1, scale: 1, rotateY: 0, transition: { duration: 0.5 } },
        hidden: { opacity: 0, scale: 0, rotateY: 180 }
    };

    const [hasAnimated, setHasAnimated] = useState(false);
    const control = useAnimation();
    const [ref, inView] = useInView();
    
    useEffect(() => {
        if (inView && !hasAnimated) {
        control.start('visible');
        setHasAnimated(true);
        }
    }, [control, inView, hasAnimated]);

    return (
          <motion.div
            className="box"
            ref={ref}
            variants={cardVariant}
            initial="hidden"
            animate={control}
            style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}
          >      
                <Box sx={{width:'100%', position:'relative', maxWidth:'1015px',   borderRadius:'20px', boxShadow: {xs:'0px 6.322751045227051px 6.322751045227051px 0px #2B97D126'}, display:'flex',  backgroundImage: `url(${HomeBg})`,   backgroundPosition: 'center', backgroundSize:'cover', flexDirection:{xs:'column',md:'row',}, mb:3}}>
                  <Box
                  component='img'
                  src={keyboardIcon2}
                  sx={{display:{xs:"block", md:'none'},position:'absolute', top:0, left:0, width:'100%', height:'100%', zIndex:'1', borderRadius:'20px'  }}
                  />
                  {/* <Box
                  component='img'
                  src={keyboardIcon2}
                  sx={{display:{xs:"block", md:'none'},position:'absolute', bottom:0, right:0, width:'60%', height:'60%', zIndex:'1', borderBottomRightRadius:'20px' }}
                  /> */}
                  <Box sx={{width:{xs:'100%',md:'60%'}, borderTopLeftRadius:'20px', borderTopRightRadius:{xs:'20px', md:'0'}, borderBottomLeftRadius:{sx:'0',md:'20px' }, display:'flex', justifyContent:'center'}}>
                      <Box 
                      component='img'
                      src={HomeMobile1}
                      sx={{zIndex:"2", width:{xs:'110%', sm:'90%'}, height:{xs:'110%', sm:'100%'}, borderTopLeftRadius:'20px', borderTopRightRadius:{xs:'20px', md:'0'}, borderBottomLeftRadius:{sx:'0',md:'20px' }}}
                      />
                  </Box>
                  <Box sx={{width:{xs:'100%',md:'40%'},display:'flex', flexDirection:'column', gap:'20px', justifyContent:'center',borderTopRightRadius:{xs:'0', md:'20px'}, borderBottomLeftRadius:{xs:'20px',md:'0' }, borderBottomRightRadius:'20px'}}> 
                    <Typography sx={{zIndex:'2', mt:{xs:2, md:0},textAlign:{xs:'center',md:'left'}, fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'17px',sm:'22px',md:'22px'}, fontWeight:'700',  color: '#fff', maxWidth:'474px', alignSelf:{xs:'center', md:'start'}, maxWidth:{xs:'240px', sm:'310px'}}}>You're one step away from the smart perfect solution</Typography>
                    <Box sx={{display:'flex', justifyContent:{xs:'center',md:'start'}}}>
                      <Button variant="contained"  sx={{zIndex:'2',mb:{xs:2, md:0},padding:'15px 20px',  borderRadius:'10px', fontSize:'14px',  '&:hover': {  textTransform:'none'}}} ><Typography sx={{textTransform:'none', fontFamily:'poppins',fontSize:{xs:'12px',sm:'14px',md:'15px'}}}> Book a Demo</Typography></Button>
                    </Box>
                    
                  </Box>
              </Box>
          </motion.div>
    
  )
}
