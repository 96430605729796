import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography, TextField } from '@mui/material';
import { motion } from 'framer-motion';
import contactlogo from '../../Assets/Contact/contactlogo.png';
import Image1 from '../../Assets/Contact/image1.jpg';
import Image2 from '../../Assets/Contact/image2.jpg';
import Image3 from '../../Assets/Contact/image3.jpg';
import Image4 from '../../Assets/Contact/image4.jpg';


export default function ContactForm() {
    const [isTab, setIsTab] = useState(window.innerWidth<900);

    const handleResize = ()=> {
      setIsTab(window.innerWidth<900);
    }
  
  
    useEffect(() => {
      window.addEventListener('resize', handleResize)
      return()=> {
        window.removeEventListener('resize',handleResize)
      }
    },[])

  return (
    <motion.div
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 2}}
    >
    <Box  sx={{width:{xs:'90%',sm:'80%',md:'90%', lg:'80%'},margin:'0 auto',mt:5, }}>
        <Box sx={{display:'flex', width:'90%', flexDirection:{xs:'column-reverse', md:'row'}, margin:'0 auto', border:'0.5px solid #D4EEFB', borderRadius:'20px', boxShadow:'4px 2px 8px 4px #0000000A', mb:3}}>   
            <Box sx={{ padding:{xs:'20px', md:'30px'},width:{xs:'90%',md:'65%'}, display:'flex', flexDirection:'column', gap:'10px'}}>
            <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'15px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left',mb:2}}>
                Have a doubt? Ask it out
            </Typography>
            <Box sx={{display:'flex', flexDirection:'column', width:'100%', gap:{xs:'30px',md:'20px'}}}>
                <Box sx={{display:'flex', flexDirection:{xs:'column',md:'row',}, flexWrap:'wrap', gap:{xs:'30px',md:'20px'}}}>
                    <TextField id="standard-basic" label="Name" InputLabelProps={{style:{color:'#9893A5'}}} variant="standard" sx={{ flex:1}}/>
                    <TextField id="standard-basic" label="Email" InputLabelProps={{style:{color:'#9893A5'}}} variant="standard" sx={{ flex:1}}/>
                </Box>
                <Box sx={{display:'flex',flexDirection:{xs:'column',md:'row',}, flexWrap:'wrap', gap:{xs:'30px',md:'20px'}}}>
                    <TextField id="standard-basic" label="Phone" InputLabelProps={{style:{color:'#9893A5'}}} variant="standard" sx={{ flex:1}}/>
                    <TextField id="standard-basic" label="Company" InputLabelProps={{style:{color:'#9893A5'}}} variant="standard" sx={{ flex:1}}/>
                </Box>
                <Box sx={{width:'100%'}}>
                <TextField
                    id="standard-multiline-static"
                    label="Message"
                    multiline
                    rows={4}
                    InputLabelProps={{style:{color:'#9893A5'}}}
                    variant="standard"
                    sx={{width:'100%'}}
                />
                </Box>
                <Box sx={{width:'100%', display:'flex', justifyContent:{xs:'center',md:'end'}}}>
                <Button variant="contained">Send</Button>
                </Box>

            </Box>
            </Box>
            <Box sx={{width:{xs:'100%',md:'65%'},display:'flex', justifyContent:'center', alignItem:'center', borderTopRightRadius:'20px', borderTopLeftRadius:{xs:'20px', md:'0'},borderBottomRightRadius:{xs:'0',md:'20px'}}}>
                <Box
                    component='img'
                    src={isTab ? Image3 : Image4}
                    sx={{width:'100%', height:'100%', maxWidth:'100%', maxHeight:'100%', objectFit:'cover',alignSelf:'center', borderTopRightRadius:'20px', borderTopLeftRadius:{xs:'20px', md:'0'},borderBottomRightRadius:{xs:'0',md:'20px'}}}
                    />
            </Box>
        </Box>
    </Box>
    </motion.div>
  )
}
