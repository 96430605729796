import React from 'react'
import { Box, Button, IconButton, Typography, Drawer } from '@mui/material';
import HeaderComponent from '../components/home/HeaderComponent'
import HeaderSlider from '../components/home/HeaderSlider';
import CustomerMap from '../components/home/CustomerMap';
import BusinessMap from '../components/home/BusinessMap';
import AnimationCard from '../components/home/AnimationCard';
import { motion } from 'framer-motion';

export default function Home() {
  return (
    <motion.Box
    initial={{opacity:0}}
    animate={{opacity:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 0.5}}
    >
        <HeaderComponent />
        <HeaderSlider/>
        <CustomerMap/>
        <BusinessMap/>
        <Box  sx={{margin:'0 auto', my:{xs:2,sm:2}, width:{xs:'80%',sm:'80%'},px:{xs:2,sm:2}, py:{xs:0,sm:2}, display:'center', justifyContent:'center'}}>
         <AnimationCard />
      </Box>
    </motion.Box>
  )
}
