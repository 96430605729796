import React from 'react'
import { Box, Button, IconButton, Typography, Drawer } from '@mui/material';
import SolutionHeader from '../components/Solutions/SolutionHeader';
import SolutionCards from '../components/Solutions/SolutionCards';
import { motion, AnimatePresence } from 'framer-motion';

export default function Solutions() {
  return (
    <motion.Box
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    > 
      <Box sx={{pb:5}}>
        <SolutionHeader />
        <SolutionCards />
      </Box>
    </motion.Box>
  )
}
