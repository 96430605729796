import React from 'react'
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import SupportHeader from '../components/Support/SupportHeader';
import SupportMain from '../components/Support/SuppportMain';

export default function Support() {
  return (
    <motion.Box
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    sx={{width:'800px', margin:'0 auto', textAlign:'center'}}
    >
        <SupportHeader />
        <SupportMain/>
    </motion.Box>
  )
}
