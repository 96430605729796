import React from 'react'
import { Box, Button, IconButton, Typography, Drawer } from '@mui/material';
import HeaderComponent from '../components/home/HeaderComponent'
import { motion, AnimatePresence } from 'framer-motion';
import ProductHeader from '../components/Product/ProductHeader';
import ViewExpenses from '../components/Product/ViewExpenses';
import DedicatedMobileApps from '../components/Product/DedicatedMobileApps';
import BusinessSolution from '../components/Product/BusinessSolution';
import AnimationCard from "../components/home/AnimationCard";
import SoftwareSolution from '../components/Product/SoftwareSolution';


export default function Product() {
  return (
    <motion.Box
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    sx={{width:'800px', margin:'0 auto', textAlign:'center'}}
    >
      <Box sx={{mb:5}}>
        <ProductHeader />
        <SoftwareSolution/>
        <ViewExpenses />
        <DedicatedMobileApps />
        <BusinessSolution />
        <Box sx={{maxWidth:'85%', margin:'0 auto', display:'flex', justifyContent:'center'}}>
          <AnimationCard />
        </Box>
      </Box>
    </motion.Box>
  )
}
