import React from 'react'
import { Box, Button, IconButton, Typography, Drawer } from '@mui/material';
import HeaderComponent from '../components/home/HeaderComponent'
import { motion, AnimatePresence } from 'framer-motion';
import AboutHeader from '../components/aboutus/AboutHeader';
import AboutCard from '../components/aboutus/AboutCard';
import Whybsmart from '../components/aboutus/Whybsmart';
import AnimationCard from "../components/home/AnimationCard";
import Vision from '../components/aboutus/Vision';


export default function Aboutus() {
  return (
    <motion.Box
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    sx={{width:'800px', margin:'0 auto', textAlign:'center'}}
    >
        <AboutHeader/>
        <Vision />
        <AboutCard/>
        <Whybsmart />
        <Box sx={{maxWidth:'85%', margin:'0 auto'}}>
          <AnimationCard />
        </Box>
    </motion.Box>
  )
}
