import React from 'react'
import { Box, Button, IconButton, Typography, Drawer } from '@mui/material';
import HeaderComponent from '../components/home/HeaderComponent'
import { motion, AnimatePresence } from 'framer-motion';
import FeaturesComponent from '../components/features/FeatureHeader';
import FeatureTabs from '../components/features/FeatureTabs';

export default function Features() {
  return (
    <motion.Box
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    >
        <FeaturesComponent />
        <FeatureTabs />
    </motion.Box>
  )
}
