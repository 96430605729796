import React from 'react'
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import PrivacyHeader from '../components/PrivacyPolicy/PrivacyHeader';
import PrivacyDescription from '../components/PrivacyPolicy/PrivacyDescription';

export default function PrivacyPolicy() {
  return (
    <motion.Box
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    sx={{width:'800px', margin:'0 auto', textAlign:'center'}}
    >
      <Box sx={{mb:3}}> 
        <PrivacyHeader />
        <PrivacyDescription/>
      </Box>
    </motion.Box>
  )
}
