import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { ReactComponent as WatchVideo } from "../../Assets/home/WatchVideoIcon.svg";
import { ReactComponent as MobileWatchVideo } from "../../Assets/home/MobileWatchVideo.svg";
import { motion } from 'framer-motion';
import TypeWriterText from './TypeWriterText';
import bg from "../../Assets/home/bg6.jpg"

export default function HeaderComponent() {
  const [isScalingUp, setIsScalingUp] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsScalingUp((prev) => !prev);
    }, 600);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    return () => clearInterval(intervalId);
  }, []);
  return (
    <Box sx={{position:'relative', backgroundImage:{xs:`url(${bg})`, sm:'none'}, backgroundPosition:'center', backgroundSize:'cover'}}>
      <Box 
      component='img'
      src={bg}
      sx={{display:{xs:'none', sm:'block'}, backgroundPosition:'center', backgroundSize:'cover',width:'100%', zIndex:-1, position:'absolute', height:'100%'}}
      />
    <motion.div 
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth:'680px', margin:'0 auto', padding:'8px'}}>
      <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', mt:3, gap:'20px', mb:{xs:0,sm:12}}}>
        <Box >
        <TypeWriterText />
        </Box>
        <Box>
          <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'center'}}>
          Effortless logistics which elevates business excellence to unlock the streamlined operations for seamless delivery.
          </Typography>
        </Box>
        <Box sx={{display:'flex', gap:'20px', flexDirection:{xs:'column', sm:'row'}}}>
          <Button variant="contained" 
                sx={{maxHeight:'56px',alignSelf:'center', padding:'10px, 16px, 10px, 16px', borderRadius:'10px', backgroundColor:'#2B97D1', 
                '&:hover': {
                  backgroundColor:'#2B97D1'
                },
              }}
                ><Typography sx={{fontFamily:'poppins',  lineHeight:'24px', fontSize:{xs:'13px',sm:'15px',md:'16px'}, fontWeight:'700',  textTransform:'none', p:1}}>Try free trial</Typography></Button>
            <Box sx={{display:{xs:'none',sm:'flex'}, alignItems:'center'}}>
            <motion.div 
             animate={{ scale: isScalingUp ? 1.1 : 0.8 }}
             transition={{ duration: 0.5, ease: 'easeInOut' }}
             whileHover={{ scale: 1.2 }}
             whileTap={{ scale: 0.8 }}
            >
              <IconButton><WatchVideo /></IconButton>
                     
            </motion.div>
            <Typography sx={{fontFamily:'poppins',  fontSize:'16px', fontWeight:'400'}}>Watch video</Typography>     
            </Box>
            <Box sx={{display:{xs:'flex',sm:'none'}, alignItems:'center'}}>
            <motion.div 
             animate={{ scale: isScalingUp ? 1.1 : 0.8 }}
             transition={{ duration: 0.5, ease: 'easeInOut' }}
             whileHover={{ scale: 1.2 }}
             whileTap={{ scale: 0.8 }}
            >
              <IconButton><MobileWatchVideo /></IconButton>
                     
            </motion.div>
                <Typography sx={{fontFamily:'poppins',  fontSize:{xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400'}}>Watch video</Typography>
            </Box>
        </Box>
      </Box>
    </motion.div>
     </Box>
  )
}
