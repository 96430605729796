import React,{useEffect, useState, useRef} from 'react'
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Box, Button, MenuItem ,Select , Switch, Checkbox, Typography,Stepper, Step, StepLabel, FormControlLabel, FormGroup, StepButton, ThemeProvider, createTheme, Slider,TextField,FormControl,FormHelperText , OutlinedInput, Input, InputLabel, InputAdornment, Fade, Modal, Backdrop  } from '@mui/material';
import { useFormik } from "formik";
import * as yup from "yup";
import styled from '@emotion/styled';
import Tick from "../../Assets/pricing/tick.png";
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { preventDatePickerKeyboard } from '../../utils/common';
import { MenuProps } from '../../utils/common';

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 300,
    minWidth:200,
    bgcolor: 'background.paper',
    textAlign:'center',
    padding:'0 30px 30px',
    color:'#333',
    borderRadius:'6px',
    boxShadow: 24,
    display:'flex',
    flexDirection:'column',
    gap:'20px',
    p: 4,
  };

  const theme = createTheme({
    overrides: {
      MuiStepIcon: {
        root: {
          width: '30px', 
          height: '30px',
        },
      },
    },
  });


  const steps = ['', '', ''];
  const demoTime = [ '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30']
export default function PriceSummary() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth<665);
    const handleResize = ()=> {
        setIsMobile(window.innerWidth<665);
      }
  
    useEffect(() => {
      window.addEventListener('resize', handleResize)
      
  
      return()=> {
        window.removeEventListener('resize',handleResize)
      }
    },[])

    const Steppers = () => {
    const today = new Date();
    let errors = {};
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone:null,
      companyName:''
    });
    const [formData2, setFormData2] = useState({
      website: '',
      amount: null,
      capacity:null,
      industry:'',
      location:'',
    });
    const [formData3, setFormData3] = useState({
      isPreferDemo: false,
      demoDate:today,
      demoTime:"",
      userQuery: "",
      isRecieveUpdate: false
    });
    const [formDataErrorStatus, setFormErrorStatus] = useState({
      email: "",
      name: "",
      phone: null
  });
  const [formData2ErrorStatus, setForm2ErrorStatus] = useState({
    amount: "",
    capacity: "",
});
    const [data, setData] = useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const handleNext = () => {
      if(activeStep == 0) {
        formDataValidation();
      }
      else if(activeStep == 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if(activeStep == 2) {
        formData3Validation()
      }
      
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };

    const handleInputChange = (name, value, isSlider) => {
        if(isSlider) {
          setFormData2({
            ...formData2,
            [name]: value,
          });
          setForm2ErrorStatus({...formData2ErrorStatus,
          [name]: ""
          })
          return 
        }
        if (name == 'phone' || name == 'amount' || name =="capacity") {
          const numericValue = value.replace(/[^0-9]/g, '');
          if(activeStep == 0) {
            setFormData({
              ...formData,
              [name]: numericValue,
            });
          } else if(activeStep == 1) {
            setFormData2({
              ...formData2,
              [name]: numericValue,
            });
          } else if(activeStep == 2) {
            setFormData3({
              ...formData3,
              [name]: numericValue,
            });
          }
        
        } else {
          if(activeStep == 0) {
            setFormData({
              ...formData,
              [name]: value,
            });
          } else if(activeStep == 1) {
            console.log('value ',value);
            setFormData2({
              ...formData2,
              [name]: value,
            });
          } else if(activeStep == 2) {
            
            if(name != 'isPreferDemo') {
            setFormData3({
              ...formData3,
              [name]: value,
            });
          }
          }
        }

        if(activeStep == 0) {
          setFormErrorStatus({
            ...formDataErrorStatus,
            [name]: ''
        })
        } else if(activeStep == 1) {
          setForm2ErrorStatus({
            ...formData2ErrorStatus,
            [name]: ''
        })
        } 

    };

    const verifyEmail = () => {
      let error = false
      const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      setFormErrorStatus((prevStatus) => ({ ...prevStatus, email: '' })); 
      
      if (formData.name  == '') {
        error = true;
        setFormErrorStatus((prevStatus) => ({ ...prevStatus, name: 'Name Required!' }));
      }
      if (!formData.email || formData.email.trim() === '') {
        error = true;
        setFormErrorStatus((prevStatus) => ({ ...prevStatus, email: 'Email Required!' }));
      } else if (!formData.email.match(emailRegex)) {
        error = true;
        setFormErrorStatus((prevStatus) => ({ ...prevStatus, email: 'Invalid email!' }));
      }
      if (formData.phone  == '' || formData.phone == null) {
        error = true;
        setFormErrorStatus((prevStatus) => ({ ...prevStatus, phone: 'Phone Number Required!' }));
      }
      return error;    
    }
console.log('formdate3 ',formData3);
    const formDataValidation = () => {
      const IsEmailValid = verifyEmail();
      if(!IsEmailValid) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

    const formData3Validation = () => {

        handleOpen()
    }

    const handleSwitchChange = (event) => {
      setFormData3({ ...formData3, ['isPreferDemo']: event.target.checked })
    };

  const handleDate = (e, datafrom) => {
    setFormData3({ ...formData3, [datafrom]: e })
  }

  const handleCheckbox = (name) => {
      setFormData3({...formData3, 
      [name]: !formData3[name]
      })
  }

    
    const IOSSwitch = styled((SwitchProps) => (
      <Switch  checked={formData3.isPreferDemo}   onChange={handleSwitchChange} focusVisibleClassName=".Mui-focusVisible" disableRipple {...SwitchProps} />
    ))(({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      mr:0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          transition: 'transform 300ms',
          '& + .MuiSwitch-track': {
            transition: 'transform 300ms',
            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : 'primary',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color:
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    }));

    const FormFieldLabel = ({children, sx}) => {
      return(
        <Typography sx={{flex:1,fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'15px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left', ...sx,}}>{children}</Typography>
      )
    }
  


      return(
        <Box sx={{backgroundColor:'#fff',  px:{xs:0,sm:2}, py:{xs:2,sm:2},  borderRadius:'12.65px',}}>
        <Box sx={{ width: '80%', margin:'0 auto', py:{xs:'10px', sm:0},  }}>
           <ThemeProvider theme={theme}>
        {/* <Stepper activeStep={activeStep}>
          {steps?.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel sx={{'& .MuiSvgIcon-root': {width:'50px', height:'38px'}}} {...labelProps}><Typography>{label}</Typography></StepLabel>
              </Step>
            );
          })}
        </Stepper> */}

<Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={index} {...stepProps} completed={completed[index]}>
              <StepButton sx={{'& .MuiSvgIcon-root': {width:'42px', height:'30px'}, '& .MuiStepIcon-root.Mui-completed': {color:'green'}}} onClick={handleStep(index)} >
                {completed[index] ? <CheckIcon style={{ color: 'green' }} /> : null}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>

        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button >Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>

              {activeStep == 0 ? (<form style={{display:'flex', alignItems:'center', flexDirection:'column', padding:'20px', gap:'30px',}} >
                <TextField
                    fullWidth
                    id="outlined-error"
                    name="name"
                    label="Name"
                    value={formData.name}
                    error={formDataErrorStatus.name ? true : false}
                    onChange={(e)=>handleInputChange('name', e.target.value)}
                    helperText={formDataErrorStatus.name ? formDataErrorStatus.name : ""}
                />
                <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    error={formDataErrorStatus.email ? true : false}
                    value={formData.email}
                    onChange={(e)=>handleInputChange('email', e.target.value)}
                    helperText={formDataErrorStatus.email ? formDataErrorStatus.email : ""}
                />
                <TextField
                    fullWidth
                    id="phone"
                    name="phone"
                    label="Phone Number"
                    value={formData.phone}
                    onChange={(e)=>handleInputChange('phone', e.target.value)}
                    error={formDataErrorStatus.phone ? true : false}
                    helperText={formDataErrorStatus.phone ? formDataErrorStatus.phone : ""}
                />        
               <TextField
                    fullWidth
                    id="companyName"
                    name="companyName"
                    label="Company Name"
                    value={formData.companyName}
                    onChange={(e)=>handleInputChange('companyName', e.target.value)}
                />       
        </form>):(activeStep == 1 ? (
                  <form style={{display:'flex', alignItems:'center', flexDirection:'column', padding:'20px', gap:'30px',}}>
                       {/* <Box sx={{ minWidth: 120 }}> */}
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Industry</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.industry}
                            name='industry'
                            label="Industry"
                            onChange={(e)=>handleInputChange('industry', e.target.value)}
                          >
                            <MenuItem value="Vegitables">Vegitables</MenuItem>
                            <MenuItem value="Fruits">Fruits</MenuItem>
                            <MenuItem value="Grocerry">grocerry</MenuItem>
                          </Select>
                        </FormControl>
                      {/* </Box> */}
                  <TextField
                      fullWidth
                      id="website"
                      name="website"
                      label="Website"
                      value={formData2.website}
                      onChange={(e) => handleInputChange('website', e.target.value)}
                  />
              <Box sx={{display:'flex', width:'100%', gap:isMobile?'10px': '30px', flexDirection:isMobile ? 'column' : 'row'}}>
                  <Box sx={{width:isMobile? '100%' : '50%'}}>
                  <FormFieldLabel>Your Company Revenue</FormFieldLabel>
                      <Slider
                          sx={{ml:'10px'}}
                          onChange={(e, value) => handleInputChange('amount', value, true)}
                          value={formData2.amount}
                          aria-label="Default"
                          valueLabelDisplay="auto"
                          step={50}
                          min={0}
                          max={1000}
                      />
                  </Box>
                  <Box sx={{width:'50%', display:'flex', justifyContent:'end', alignSelf:'center'}}>
                      <FormControl  >
                          <OutlinedInput
                              id="amount"
                              name="amount"    
                              value={formData2.amount}                    
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              style={{border: formData2ErrorStatus.amount ? '2px solid red' :'2px solid #1976d2', backgroundColor:'#A8CBEE'}}
                              onChange={(e) => handleInputChange('amount', e.target.value)}
                              error={formData2ErrorStatus.amount ? true : false}
                          />
                             <FormHelperText error={formData2ErrorStatus.amount ? true : false}>
                                  {formData2ErrorStatus.amount ? formData2ErrorStatus.amount : ""}
                              </FormHelperText>
                      </FormControl>
                  </Box>
              </Box>
              <Box sx={{display:'flex', width:'100%', gap: isMobile?'10px': '30px', flexDirection:isMobile ? 'column' : 'row'}}>
                  <Box sx={{width: isMobile? '100%' : '50%'}}>
                  <FormFieldLabel>Your Company Capacity</FormFieldLabel>
                      <Slider
                          sx={{ml:'10px'}}
                          onChange={(e, value) => handleInputChange('capacity', value, true)}
                          aria-label="Default"
                          valueLabelDisplay="auto"
                          step={1}
                          min={0}
                          max={1000}
                          value={formData2.capacity}
                      />
                  </Box>
                  <Box sx={{width:'50%', display:'flex', justifyContent:'end',alignSelf:'center'}}>
                      <FormControl  >
                          <OutlinedInput
                              id="capacity"
                              name="capacity"
                              style={{border: formData2ErrorStatus.capacity ? '2px solid red' :'2px solid #1976d2', backgroundColor:'#A8CBEE'}}
                              onChange={(e) => handleInputChange('capacity', e.target.value)}
                              value={formData2.capacity}
                              error={formData2ErrorStatus.capacity ? true : false}
                          />
                           <FormHelperText error={formData2ErrorStatus.capacity ? true : false}>
                                  {formData2ErrorStatus.capacity ? formData2ErrorStatus.capacity : ""}
                              </FormHelperText>
                      </FormControl>
                  </Box>
              </Box>
              <TextField
                      fullWidth
                      id="location"
                      name="location"
                      label="Location"
                      value={formData2.location}
                      onChange={(e) => handleInputChange('location', e.target.value)}
                  />
              
          </form>
        ):(activeStep == 2 ? (
          <form style={{display:'flex', alignItems:'center', flexDirection:'column', padding:'20px', gap:'30px',}}>
              <Box sx={{display:'flex', gap:'10px', alignItems:'center', width:'100%'}}>
                <FormFieldLabel>Do you want to take Demo?</FormFieldLabel>
                  <FormGroup>
                  <FormControlLabel sx={{mr:0}}
                    control={<IOSSwitch sx={{ m: 1, flex:'1'}} defaultChecked={false} />}
                  />
                </FormGroup>
              </Box>
              <Box sx={{display:'flex', gap:'10px', flexDirection:{xs:'column', sm:'row'}, justifyContent:'space-between',  width:"100%"}}>
                <Box sx={{}}>
                  <FormFieldLabel>Date</FormFieldLabel>
                  <DesktopDatePicker
                    inputFormat='dd/MM/yyyy'
                    disabled={formData3.isPreferDemo ? false : true}
                    disablePast              
                    value={formData3.demoDate}
                    onChange={(e) => handleDate(e, 'demoDate')}
                    inputProps={{style:{fontSize:'14px'}}}
                    renderInput={(params) => <TextField  onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                </Box>
                <Box >
                  <FormFieldLabel>Time</FormFieldLabel>
                  <Select disabled={formData3.isPreferDemo ? false : true} fullWidth sx={{display:"block", minWidth:'100px', maxWidth:'260px'}}  MenuProps={MenuProps} value={formData3?.demoTime ? formData3?.demoTime : ""} onChange={(e) => handleInputChange('demoTime', e.target.value)}>
                    {demoTime?.map((value)=>(
                        <MenuItem sx={{color:'#0F2C6F'}} value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Any Queries?"
                multiline
                inputProps={{style:{fontSize:'14px'}}}
                rows={4}
                onChange={(e) => handleInputChange('userQuery', e.target.value)}
              />
              <Box sx={{display:'flex', gap:'10px'}}>
              <FormFieldLabel sx={{letterSpacing:'0.02em', fontSize:'14px'}}>Would you like to recive newsletters, updates, or marketing communications</FormFieldLabel>
              <FormControlLabel control={<Checkbox onClick={() => handleCheckbox('isRecieveUpdate')} />} />
              </Box>
          </form>
        ):('')))}  

            {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />  
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box> */}
                     <Box sx={{ display: 'flex', flexDirection: 'row', pt:2, px: "20px",  justifyContent:'space-between' }}>
                      <Box>
                          <Button variant='outlined' color="primary" sx={{ mr: 1, padding:'8px 16px', borderRadius:'10px', fontSize:{xs:'13px',sm:'14px',md:'15px'}}} disabled={activeStep === 0} onClick={handleBack} >
                            Back
                          </Button>
                      </Box>

            <Box sx={{  }} />
            <Button onClick={handleNext} color="primary" variant="contained"  type="submit" sx={{padding:'8px 16px', borderRadius:'10px', fontSize:{xs:'13px',sm:'14px',md:'15px'}}}>
              {activeStep === steps.length - 1 ? 'get Quote' : 'Next'}
            </Button>
          </Box>
          </React.Fragment>
        )}
        </ThemeProvider>
      </Box>
      </Box>
      )
    }

    const ModalAlert = () => {
        return(
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Box>
                    <Box 
                    component='img'
                    src={Tick}
                    sx={{width:{xs:'70px',sm:'100px'}, position:'relative', top:{xs:'-10px', sm:'0'}, marginTop:'-100px', borderRadius:'50%', boxShadow:'0 2px 5px rgba(0,0,0,0.2)'}}
                    />
                </Box>
                <Typography id="transition-modal-title" variant="h6" component="h2">
                  Thank You!
                </Typography>
                <Typography id="transition-modal-description" >
                  Your details has been successfully submitted, Thanks!
                </Typography>
                <Box>
                    <Button color="primary" variant="contained" fullWidth sx={{padding:'8px 16px', borderRadius:'10px', fontSize:'15px'}} onClick={handleClose}>OK</Button>
                </Box>
              </Box>
            </Fade>
          </Modal>
        )
    }


  return (
    <motion.div
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    >
        <ModalAlert />
      <Box sx={{width:{xs:'80%',sm:'50%',md:'30%'}, maxWidth:{xs:'604px',md:'604px'}, margin:'0 auto',mt:5, display:'flex', flexDirection:'column', gap:'20px', border:'7px solid #2B97D1',  borderRadius:'12.65px', boxShadow: {xs:'0px 6.322751045227051px 6.322751045227051px 6.322751045227051px #2B97D126'}}}>
        <Steppers/>
      </Box>
    </motion.div>
  )
}
