import React from 'react'
import { Box, Button, IconButton, Typography, Drawer } from '@mui/material';
import HeaderComponent from '../components/home/HeaderComponent'
import { motion, AnimatePresence } from 'framer-motion';
import ContactusHeader from '../components/ContactUs/ContactusHeader';
import ContactForm from '../components/ContactUs/ContactForm';

export default function Contact() {
  return (
    <motion.Box
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    sx={{width:'800px', margin:'0 auto', textAlign:'center'}}
    >
      <Box sx={{mb:5}}>
        <ContactusHeader />
        <ContactForm />
      </Box>
    </motion.Box>
  )
}
