import Mobile from "../../Assets/Product/MobileApps/Mobile.png";
import Dashboard1 from "../../Assets/Features/dashboards1.png";
import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography, Tabs, Tab } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';

const featurePassage = [
    {value:1, name:"Customer app:", subHead:["Track Your Loads: ", "Instant Credit Updates: ", "Convenient Bill Downloads: ", "Automated Billing Clarity: ", "Enhanced Customer Experience: "],
    subText: ["Stay informed with real-time load tracking in our Customer App.", "No more reminders! Credits seamlessly update with corresponding invoice bills immediately after processing.", "Download customer bills effortlessly in PDF format for easy reference and record-keeping.", "Experience hassle-free credit updates alongside customer invoices, eliminating the need for separate reminders or notifications.","Enjoy a seamless, integrated system that keeps you updated and offers multiple convenient ways to manage your orders and bills."]},
    {value:2, name:"Sales driven App:", subHead:["Track Your Shipments: ", "Driver Status Alert: ",],
    subText: ["Stay updated on load locations and movement in real-time.", "Receive notifications if drivers halt or take breaks, ensuring continuous monitoring and proactive management."]},
    {value:3, name:"Trip Management:", subHead:["Automated Delivery Management: ", "Adaptive Automation: ",],
    subText: ['Bid farewell to manual "assign drivers to vehicles" practices. Our Trip Management automates this process, setting it up once for seamless daily operations.', "Set up your delivery process once and let it run on autopilot. Make changes when needed without disrupting the automated flow."]},
    {value:4, name:"Reports:", subHead:["Sales Reports: ", "Purchase Reports: ","GST: ","Expenses: "],
    subText: ['Quick overview of total sales and revenue', "Detailed records of all purchases made, including Supplier information, purchase dates, and quantities.", "Summary of GST collected and paid, segregated by tax rates or categories for compliance", "Analysis of expense patterns over time, helping in budgeting and cost control measures"]},
    {value:5, name:"Profits:", subHead:["Instant Profit Visibility: ", "Avoiding Losses: ","Accelerated Business Growth: "],
    subText: ['Know your profit or loss instantly at the order stage. ', "By identifying losses upfront, our application shows you to avoid processing bills for unprofitable orders.", "Profitability analysis drives business growth by identifying fast/slow-moving stock items and meeting customer needs effectively."]},
]
export default function FeatureData ({tabValue})  {
    const [featureList, setFeatureList] = useState(featurePassage);
    const [rotation, setRotation] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
      const { clientX, clientY, target } = e;
      const { left, top, width, height } = target.getBoundingClientRect();
      const x = ((clientX - left) / width - 0.5) * 2;
      const y = ((clientY - top) / height - 0.5) * 2;
  
      setRotation({ x, y });
    };

    const handleMouseLeave = () => {
      setRotation({ x: 0, y: 0 });
    };

    useEffect(() => {
        console.log('tabValue ',tabValue);
        if(featureList) {
            const filteredData = featureList?.filter((item) => item.value == tabValue)
            setFeatureList(filteredData);
            console.log('featurePassage ',filteredData);
        }

    },[tabValue])
  
    return(
        <Box sx={{mt:4, margin:'0 auto', my:2, width:'80%',}}>
            <Box id='featureData'>
          
                    { tabValue == 0 && 
                          <motion.div
                          initial={{opacity:0,scale:0.5}}
                          animate={{opacity:1,scale:1}}
                          // exit={{opacity:0}}
                          transition={{delay:0.3, duration: 1}}
                          style={{marginBottom:'16px'}}
                          >
                            <Box  sx={{mt:5,display:'flex', gap:'40px', width:'100%', flexDirection:{xs:'column', md:'row'}}} >
                            <Box sx={{display:'flex', flexDirection:'column',width:{xs:'100%',md:'50%'}, alignItems:{xs:'center',md:'start'}, justifyContent:'center', alignSelf:{xs:'center'}}}>
                                <Typography  sx={{alignSelf:'start', padding:'0 10px 10px 10px',fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'25px',sm:'30px',md:'35x'}, fontWeight:'500', color: '#31284C', alignSelf:{xs:'center',md:'start'}, textAlign:{xs:'center',md:'left'}}}>
                                Dashboard Analysis
                                </Typography>
                                <ul style={{color:'#31284C', fontFamily:'poppins',}}> 
                                    <li style={{marginBottom:'10px', fontSize:'16px'}}>Order Management<ul>
                                        <li style={{lineHeight:'28px', listStyleType:'circle'}}><strong>Real-time Order Status:</strong> Track total orders received, pending, and delivered for operational clarity.</li>
                                        <li style={{lineHeight:'28px', listStyleType:'circle'}}><strong>Order Ratio Insights:</strong> Visualize order ratio trends to streamline fulfillment processes efficiently.</li>
                                    </ul></li>
                                    <li style={{marginBottom:'10px', fontSize:'16px'}}>Payment and Financial Analysis:<ul>
                                        <li style={{lineHeight:'28px', listStyleType:'circle'}}><strong>Payment Ratio Visualization:</strong> Illustrate payment trends and ratios for financial insights.</li>
                                        <li style={{lineHeight:'28px', listStyleType:'circle'}}><strong>Earnings Overview:</strong> Display earnings summary for quick financial assessment.</li>
                                    </ul></li>
                                    <li style={{marginBottom:'10px', fontSize:'16px'}}>Sales and Profit Indicators:<ul>
                                        <li style={{lineHeight:'28px', listStyleType:'circle'}}><strong>Sales Performance Metrics:</strong> Highlight sales figures and trends for business growth analysis.</li>
                                        <li style={{lineHeight:'28px', listStyleType:'circle'}}><strong>Profit Analysis:</strong> Visualize profit margins to gauge business profitability.</li>
                                    </ul></li>
                                    <li style={{marginBottom:'10px', fontSize:'16px'}}>Expense Monitoring:<ul>
                                        <li style={{lineHeight:'28px', listStyleType:'circle'}}><strong>Expense Tracking:</strong> Display total expenses incurred, aiding in cost control measures.</li>
                                    </ul></li>
                                </ul>
                                
                            </Box>
                            <Box sx={{width:{xs:'100%',md:'50%'},  alignSelf:{xs:'center'}, transition:'transform 0.5s ease', position:'relative', perspective:'1000px',}}
                                 onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                                <Box  component='img' src={Dashboard1} sx={{width:'100%', height:'100%',  objectFit:'cover', transition:'transform 0.3s ease',  '&:hover': {transform:'rotateX(20deg)' }}}
                                    style={{transform: `rotateX(${rotation.y * 20}deg) rotateY(${-rotation.x * 20}deg)`,}}/>
                                </Box>
                        </Box>
                    </motion.div>
                    } 
                
                { tabValue == 1 && 
                          <motion.div
                          initial={{opacity:0,scale:0.5}}
                          animate={{opacity:1,scale:1}}
                          // exit={{opacity:0}}
                          transition={{delay:0.3, duration: 1}}
                          style={{marginBottom:'16px'}}
                          >
                            <Box  sx={{mt:5,display:'flex', gap:'40px', width:'100%', flexDirection:{xs:'column', md:'row'}}} >
                            <Box sx={{display:'flex', flexDirection:'column',width:{xs:'100%',md:'50%'}, alignItems:{xs:'center',md:'start'}, justifyContent:'center', alignSelf:{xs:'center'}}}>
                                <Typography  sx={{alignSelf:'start', padding:'0 10px 10px 10px',fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'25px',sm:'30px',md:'35x'}, fontWeight:'500', color: '#31284C', alignSelf:{xs:'center',md:'start'}, textAlign:{xs:'center',md:'left'}}}>
                                Customer App
                                </Typography>
                                <ul style={{color:'#31284C', fontFamily:'poppins',}}> 
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Instant Credit Updates: </strong>No more reminders! Credits seamlessly update with corresponding invoice bills immediately after processing.</li>
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Track Your Loads: </strong>Stay informed with real-time load tracking in our Customer App.</li>
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Convenient Bill Downloads: </strong>Download customer bills effortlessly in PDF format for easy reference and record-keeping.</li>
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Automated Billing Clarity: </strong>Experience hassle-free credit updates alongside customer invoices, eliminating the need for separate reminders or notifications.</li>
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Enhanced Customer Experience: </strong> Enjoy a seamless, integrated system that keeps you updated and offers multiple convenient ways to manage your orders and bills.</li>
                                </ul>
                                
                            </Box>
                            <Box sx={{width:{xs:'100%',md:'50%'},  alignSelf:{xs:'center'}, transition:'transform 0.5s ease', position:'relative', perspective:'1000px',}}
                                 onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                                <Box  component='img' src={Mobile} sx={{width:'100%', height:'100%',  objectFit:'cover', transition:'transform 0.3s ease',  '&:hover': {transform:'rotateX(20deg)' }}}
                                    style={{transform: `rotateX(${rotation.y * 20}deg) rotateY(${-rotation.x * 20}deg)`,}}/>
                                </Box>
                        </Box>
                    </motion.div>
                    } 

                    { tabValue == 2 && 
                          <motion.div
                          initial={{opacity:0,scale:0.5}}
                          animate={{opacity:1,scale:1}}
                          // exit={{opacity:0}}
                          transition={{delay:0.3, duration: 1}}
                          style={{marginBottom:'16px'}}
                          >
                            <Box  sx={{mt:5,display:'flex', gap:'40px', width:'100%', flexDirection:{xs:'column', md:'row'}}} >
                            <Box sx={{display:'flex', flexDirection:'column',width:{xs:'100%',md:'50%'}, alignItems:{xs:'center',md:'start'}, justifyContent:'center', alignSelf:{xs:'center'}}}>
                                <Typography  sx={{alignSelf:'start', padding:'0 10px 10px 10px',fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'25px',sm:'30px',md:'35x'}, fontWeight:'500', color: '#31284C', alignSelf:{xs:'center',md:'start'}, textAlign:{xs:'center',md:'left'}}}>
                                Sales Driven App
                                </Typography>
                                <ul style={{color:'#31284C', fontFamily:'poppins',}}> 
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Track Your Shipments: </strong>Stay updated on load locations and movement in real-time.</li>
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Driver Status Alert: </strong>Receive notifications if drivers halt or take breaks, ensuring continuous monitoring and proactive management.</li>
                                </ul>
                                
                            </Box>
                            <Box sx={{width:{xs:'100%',md:'50%'},  alignSelf:{xs:'center'}, transition:'transform 0.5s ease', position:'relative', perspective:'1000px',}}
                                 onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                                <Box  component='img' src={Mobile} sx={{width:'100%', height:'100%',  objectFit:'cover', transition:'transform 0.3s ease',  '&:hover': {transform:'rotateX(20deg)' }}}
                                    style={{transform: `rotateX(${rotation.y * 20}deg) rotateY(${-rotation.x * 20}deg)`,}}/>
                                </Box>
                        </Box>
                    </motion.div>
                    } 


                    { tabValue == 3 && 
                          <motion.div
                          initial={{opacity:0,scale:0.5}}
                          animate={{opacity:1,scale:1}}
                          // exit={{opacity:0}}
                          transition={{delay:0.3, duration: 1}}
                          style={{marginBottom:'16px'}}
                          >
                            <Box  sx={{mt:5,display:'flex', gap:'40px', width:'100%', flexDirection:{xs:'column', md:'row'}}} >
                            <Box sx={{display:'flex', flexDirection:'column',width:{xs:'100%',md:'50%'}, alignItems:{xs:'center',md:'start'}, justifyContent:'center', alignSelf:{xs:'center'}}}>
                                <Typography  sx={{alignSelf:'start', padding:'0 10px 10px 10px',fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'25px',sm:'30px',md:'35x'}, fontWeight:'500', color: '#31284C', alignSelf:{xs:'center',md:'start'}, textAlign:{xs:'center',md:'left'}}}>
                                Trip Management
                                </Typography>
                                <ul style={{color:'#31284C', fontFamily:'poppins',}}> 
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Automated Delivery Management: </strong>Bid farewell to manual "assign drivers to vehicles" practices. Our Trip Management automates this process, setting it up once for seamless daily operations.</li>
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Adaptive Automation: </strong>Set up your delivery process once and let it run on autopilot. Make changes when needed without disrupting the automated flow.</li>
                                </ul>
                                
                            </Box>
                            <Box sx={{width:{xs:'100%',md:'50%'},  alignSelf:{xs:'center'}, transition:'transform 0.5s ease', position:'relative', perspective:'1000px',}}
                                 onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                                <Box  component='img' src={Mobile} sx={{width:'100%', height:'100%',  objectFit:'cover', transition:'transform 0.3s ease',  '&:hover': {transform:'rotateX(20deg)' }}}
                                    style={{transform: `rotateX(${rotation.y * 20}deg) rotateY(${-rotation.x * 20}deg)`,}}/>
                                </Box>
                        </Box>
                    </motion.div>
                    } 

                    { tabValue == 4 && 
                          <motion.div
                          initial={{opacity:0,scale:0.5}}
                          animate={{opacity:1,scale:1}}
                          // exit={{opacity:0}}
                          transition={{delay:0.3, duration: 1}}
                          style={{marginBottom:'16px'}}
                          >
                            <Box  sx={{mt:5,display:'flex', gap:'40px', width:'100%', flexDirection:{xs:'column', md:'row'}}} >
                            <Box sx={{display:'flex', flexDirection:'column',width:{xs:'100%',md:'50%'}, alignItems:{xs:'center',md:'start'}, justifyContent:'center', alignSelf:{xs:'center'}}}>
                                <Typography  sx={{alignSelf:'start', padding:'0 10px 10px 10px',fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'25px',sm:'30px',md:'35x'}, fontWeight:'500', color: '#31284C', alignSelf:{xs:'center',md:'start'}, textAlign:{xs:'center',md:'left'}}}>
                                Reports
                                </Typography>
                                <ul style={{color:'#31284C', fontFamily:'poppins',}}> 
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Sales Reports: </strong>Quick overview of total sales and revenue</li>
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Purchase Reports: </strong>Detailed records of all purchases made, including Supplier information, purchase dates, and quantities.</li>
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>GST: </strong>Summary of GST collected and paid, segregated by tax rates or categories for compliance</li>
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Expenses: </strong>Analysis of expense patterns over time, helping in budgeting and cost control measures</li>
                                </ul>
                                
                            </Box>
                            <Box sx={{width:{xs:'100%',md:'50%'},  alignSelf:{xs:'center'}, transition:'transform 0.5s ease', position:'relative', perspective:'1000px',}}
                                 onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                                <Box  component='img' src={Mobile} sx={{width:'100%', height:'100%',  objectFit:'cover', transition:'transform 0.3s ease',  '&:hover': {transform:'rotateX(20deg)' }}}
                                    style={{transform: `rotateX(${rotation.y * 20}deg) rotateY(${-rotation.x * 20}deg)`,}}/>
                                </Box>
                        </Box>
                    </motion.div>
                    } 

                { tabValue == 5 && 
                          <motion.div
                          initial={{opacity:0,scale:0.5}}
                          animate={{opacity:1,scale:1}}
                          // exit={{opacity:0}}
                          transition={{delay:0.3, duration: 1}}
                          style={{marginBottom:'16px'}}
                          >
                            <Box  sx={{mt:5,display:'flex', gap:'40px', width:'100%', flexDirection:{xs:'column', md:'row'}}} >
                            <Box sx={{display:'flex', flexDirection:'column',width:{xs:'100%',md:'50%'}, alignItems:{xs:'center',md:'start'}, justifyContent:'center', alignSelf:{xs:'center'}}}>
                                <Typography  sx={{alignSelf:'start', padding:'0 10px 10px 10px',fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'25px',sm:'30px',md:'35x'}, fontWeight:'500', color: '#31284C', alignSelf:{xs:'center',md:'start'}, textAlign:{xs:'center',md:'left'}}}>
                                Profits
                                </Typography>
                                <ul style={{color:'#31284C', fontFamily:'poppins',}}> 
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Instant Profit Visibility: </strong>Know your profit or loss instantly at the order stage. </li>
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Avoiding Losses: </strong>By identifying losses upfront, our application shows you to avoid processing bills for unprofitable orders.</li>
                                    <li style={{marginBottom:'10px',lineHeight:'28px', listStyleType:'circle'}}><strong>Accelerated Business Growth: </strong>Profitability analysis drives business growth by identifying fast/slow-moving stock items and meeting customer needs effectively. </li>
                                </ul>
                                
                            </Box>
                            <Box sx={{width:{xs:'100%',md:'50%'},  alignSelf:{xs:'center'}, transition:'transform 0.5s ease', position:'relative', perspective:'1000px',}}
                                 onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                                <Box  component='img' src={Mobile} sx={{width:'100%', height:'100%',  objectFit:'cover', transition:'transform 0.3s ease',  '&:hover': {transform:'rotateX(20deg)' }}}
                                    style={{transform: `rotateX(${rotation.y * 20}deg) rotateY(${-rotation.x * 20}deg)`,}}/>
                                </Box>
                        </Box>
                    </motion.div>
                    } 

                
            </Box>
        </Box>
    )
}




















// {
//     tabValue !=0 && featureList.map((item,index) => (
//         <motion.div
//         key={index}
//         initial={{opacity:0,scale:0.5}}
//         animate={{opacity:1,scale:1}}
//         // exit={{opacity:0}}
//         transition={{delay:0.3, duration: 1}}
//         style={{marginBottom:'16px'}}
//         >
//           <Box  sx={{mt:5,display:'flex', gap:'40px', width:'100%', flexDirection:{xs:'column', sm:'row'}}} >
//           <Box sx={{display:'flex', flexDirection:'column',width:{xs:'100%',sm:'50%'}, alignItems:{xs:'center',sm:'start'}, justifyContent:'center', alignSelf:{xs:'center'}}}>
//               <Typography  sx={{alignSelf:'start', padding:'0 10px 10px 10px',fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'25px',sm:'30px',md:'35x'}, fontWeight:'500', color: '#31284C', alignSelf:{xs:'center',sm:'start'}, textAlign:{xs:'center',sm:'left'}}}>
//               {item.name}
//               </Typography>
//               <ul style={{color:'#31284C', fontFamily:'poppins',}}> 
//                 {item.subHead.map((element, index) => (
//                     <React.Fragment key={index}>
//                       <li style={{lineHeight:'28px', listStyleType:'circle'}}><strong>{item.subHead}</strong>{item.subText[index]}</li>
//                       {/* <li style={{lineHeight:'28px', listStyleType:'circle'}}><strong>Order Ratio Insights:</strong> Visualize order ratio trends to streamline fulfillment processes efficiently.</li> */}
//                     </React.Fragment>  
//                 ))}
                

//               </ul>
              
//           </Box>
//           <Box sx={{width:{xs:'100%',sm:'50%'},  alignSelf:{xs:'center'}, transition:'transform 0.5s ease', position:'relative', perspective:'1000px',}}
//                onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
//               <Box  component='img' src={Mobile} sx={{width:'100%', height:'100%',  objectFit:'cover', transition:'transform 0.3s ease',  '&:hover': {transform:'rotateX(20deg)' }}}
//                   style={{transform: `rotateX(${rotation.y * 20}deg) rotateY(${-rotation.x * 20}deg)`,}}/>
//               </Box>
//       </Box>
//   </motion.div>
//     ))
// }