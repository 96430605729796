import React, {useEffect, useState} from 'react'
import { Box, Button, IconButton, Typography, Drawer  } from '@mui/material';
import { RiCopyrightLine } from "react-icons/ri";
import logo from '../Assets/Footer/Footerlogo.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as Facebook} from '../Assets/Footer/Facebook.svg';
import { ReactComponent as Instagram} from '../Assets/Footer/Instagram.svg';
import { ReactComponent as Twitter} from '../Assets/Footer/Twitter.svg';
import { ReactComponent as Linkedin} from '../Assets/Footer/Linkedin.svg';
import { ReactComponent as Youtube} from '../Assets/Footer/Youtube.svg';
import { ReactComponent as Email} from '../Assets/Footer/Email.svg';
import logo2 from '../Assets/Footer/logo2.png';
import bsmartLogo from '../Assets/Footer/logo.png';
import bg from "../Assets/home/bg3.jpg"
import powersoftLogo from '../Assets/Footer/powersoftLogo.png';

const socialIcons = [
  {id:1, route:'facebook.com', icon:<Facebook />},
  {id:2, route:'instagram.com', icon:<Instagram />},
  {id:3, route:'twitter.com', icon:<Twitter />},
  {id:4, route:'linkedin.com', icon:<Linkedin />},
  {id:5, route:'youtube.com', icon:<Youtube />},
  // {id:6, route:'mail.google.com', icon:<Email />}

]
export default function Footer() {
  const navigate = useNavigate();
  const [isShortMobile, setIsShortMobile] = useState(window.innerWidth<504);
  const [isMiniMobile, setIsMiniMobile] = useState(window.innerWidth<360);
  const [isTap, setIsTap] = useState(window.innerWidth<695);

  const handleResize = ()=> {
    setIsShortMobile(window.innerWidth<504);
    setIsTap(window.innerWidth<695);
    setIsMiniMobile(window.innerWidth<360)
  }


  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return()=> {
      window.removeEventListener('resize',handleResize)
    }
  },[])

  return (
    //mt:10
    <Box sx={{width:'100%', bottom: 0,  position:'relative',  boxShadow:'10px 10px 10px 11px #00000026', backgroundImage: `url(${bg})`, backgroundPosition:'center', backgroundSize:'cover'}}>       <Box sx={{ maxWidth:'90%',  display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between',  margin:'0 auto', padding:'1rem'}}>
      {/* <Box sx={{width:'100%', borderBottom: "1px solid #CCCCCC"}}/> */}
    <Box sx={{display:'flex', flexDirection:'column', width:'100%'}}>
      {/* <Box sx={{border:'0.1px solid #CCCCCC', width:'100%', borderWidth:'0.1px', my:1}}/> */}
        <Box sx={{display:'flex', my:2, justifyContent:{sm:'start'}, width:'100%', marginRight:'20px'}}>
            <Box 
            component='img'
            src={bsmartLogo}
            sx={{height:'auto', width:'150px'}}
            />
        </Box>
        {/* <Box sx={{border:'1px solid #CCCCCC', width:"100%", borderWidth:'1px', my:1, mb:2}}/> */}
      <Box sx={{display:'flex', justifyContent:'space-between', flexDirection:{xs:'column', sm:'row'}, gap:{xs:'20px', sm:'20px', md:0}, marginBottom:'30px', flexWrap:'wrap'}}>
        <Box sx={{display:'flex', flex:1, flexDirection:'column', justifyContent:'space-between', flex:1,  marginRight:{xs:'0',sm:'20px'}}}>
            <Typography sx={{maxWidth:'600px', minWidth:{sm:'360px'},  mb:{xs:2,md:2},fontFamily:'poppins', lineHeight:'26px', letterSpacing:'0.02em', fontSize: '12px', fontWeight:'600', color: 'black',  textAlign:'justify'}}>
            "Discover BSMART - Your Ultimate All-in-One Operations Solution! Seamlessly integrate tasks like Order management, Profit Analysis Tracker, Inventory Control and Fulfillment, Shipment monitoring and Automated Analytics Insights in one cohesive application. With a Mobile Application feature, streamline, manage, and optimize operations effortlessly. Elevate your business with our comprehensive solution for efficient and smart operations!"
            </Typography>
            <Box sx={{display:'flex',flexDirection:'column', gap:'5px', pb:{xs:2,md:0}, alignSelf:{xs:'start',sm:'start'}, mt:{xs:'20px', sm:'0'}}}>
              <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize:'12px', fontWeight:'900', color: '#31284C', textAlign:{xs:'left',sm:'left'}}}>
              Product of
              </Typography>
              <Box 
                component='img'
                src={logo2}
                sx={{height:'auto', width:'150px'}}
              />
          </Box>
        </Box>
      <Box sx={{display:'flex', flex:1, gap:'30px', px:{xs:'10px',md:''},  flexDirection: {xs:'column',sm :'row'}}}>
        <Box sx={{flex:1,display:'flex', gap:'20px', justifyContent:{xs:'start', sm:'center'}}}>
          <Box sx={{display:'flex', flexDirection:'column', gap:'20px'}}>
            <NavLink  to={'/aboutus'} style={{textDecoration: 'none', color: 'black', position: 'relative',}}>
                  <Typography sx={{fontFamily:'poppins', lineHeight:'21px', fontSize:{xs:'12px',sm:'14px'}, fontWeight:'500', '&:hover':{color:'rgb(107, 101, 122)', fontWeight:'600'}}}>ABOUT</Typography>
            </NavLink>
            <NavLink  to={'/product'} style={{textDecoration: 'none', color: 'black', position: 'relative',}}>
                <Typography sx={{fontFamily:'poppins', lineHeight:'21px', fontSize:{xs:'12px',sm:'14px'}, fontWeight:'500','&:hover':{color:'rgb(107, 101, 122)', fontWeight:'600'}}}>PRODUCT</Typography>
            </NavLink>
            {/* <NavLink  to={'/contact'} style={{textDecoration: 'none', color: '#6b657a', position: 'relative',}}>
                <Typography sx={{fontFamily:'poppins', lineHeight:'21px', fontSize:{xs:'12px',sm:'14px'}, fontWeight:'500','&:hover':{color:'#31284C', fontWeight:'600'}}}>CONTACT</Typography>
            </NavLink> */}
            <NavLink  to={'/faq'} style={{textDecoration: 'none', color: 'black', position: 'relative',}}>
              <Typography sx={{fontFamily:'poppins', lineHeight:'21px', fontSize:{xs:'12px',sm:'14px'}, fontWeight:'500','&:hover':{color:'rgb(107, 101, 122)', fontWeight:'600'}}}>FAQ</Typography>
            </NavLink>
          </Box>

          <Box sx={{display:'flex', flexDirection:'column', gap:'20px'}}>
        
            <NavLink  to={'/privacy'} style={{textDecoration: 'none', color: 'black', position: 'relative',}}>
                <Typography sx={{fontFamily:'poppins', lineHeight:'21px', fontSize:{xs:'12px',sm:'14px'}, fontWeight:'500','&:hover':{color:'rgb(107, 101, 122)', fontWeight:'600'}}}>PRIVACY</Typography>
            </NavLink>
            <NavLink  to={'/support'} style={{textDecoration: 'none', color: 'black', position: 'relative',}}>
                <Typography sx={{fontFamily:'poppins', lineHeight:'21px', fontSize:{xs:'12px',sm:'14px'}, fontWeight:'500','&:hover':{color:'rgb(107, 101, 122)', fontWeight:'600'}}}>HELP & SUPPORT</Typography>
            </NavLink>
          </Box>
        </Box>
        <Box sx={{display:'flex', flex:1, flexDirection:'column', gap:'20px', alignItems:{xs:'start', sm:'end'}, marginTop:{xs:'-5px'}, flexWrap:'wrap' }}>
          <Box sx={{display:'flex', justifyContent:'start', flexDirection:'column', gap:'20px', alignItems:'start'}}>
          <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'12px',sm:'14px'}, fontWeight:'900', color: '#31284C', textAlign:{xs:'center',sm:'left'}}}>
            Support
          </Typography>
          <NavLink  to={'/contact'} style={{textDecoration: 'none', color: '#6b657a', position: 'relative',}}>
            <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'12px',sm:'14px'}, fontWeight:'500', color: '#6b657a', textAlign:{xs:'center',sm:'left'}, cursor:'pointer', '&:hover':{color:'#31284C', fontWeight:'600'}}}>
              Contact us
            </Typography>
          </NavLink>
          <Typography  sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'12px',sm:'14px'}, fontWeight:'900', color: '#31284C', textAlign:{xs:'center',sm:'left'}}}>
            Connect us
          </Typography>
          <Box sx={{display:'flex', alignItems:'center', gap:'5px', justifyContent:{xs:'center',sm:'start'}}}>
            <Box sx={{height:'20px', width:'20px'}}>
              <Email style={{height:'20px', width:'20px'}}/>
            </Box>
            <Typography sx={{fontFamily:'poppins', lineHeight:'4px', fontSize: {xs:'12px',sm:'14px'}, fontWeight:'500', color: '#6b657a', textAlign:{xs:'center',sm:'left'},cursor:'pointer', '&:hover':{color:'#31284C', fontWeight:'600'}}}>
              support@bsmart.com
            </Typography>
          </Box>
          <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'center',}}>
          {socialIcons.map((item, index) => (
              <IconButton sx={{height:{xs:'35px',sm:'36px'}, width:{xs:'35px',sm:'36px'}}} key={index}>{item.icon}</IconButton>
          ))}
          </Box>
          </Box>
        </Box>
        </Box>
      </Box>
      {/* <Box sx={{display:'flex',flexDirection:'column', gap:'5px', pb:{xs:2,md:0}, alignSelf:{xs:'center',sm:'start'}}}>
      <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'16px',sm:'16px',md:'16px'}, fontWeight:'600', color: '#31284C', textAlign:{xs:'center',sm:'left'}}}>
            Product of
          </Typography>
            <Box 
              component='img'
              src={logo2}
              sx={{height:'auto', width:{xs:'100px',sm:'150px'}}}
            />
      </Box> */}
      <Box sx={{display:'flex', alignItems: isShortMobile ? 'flex-start': 'center', gap:'5px', mb:1, justifyContent:'center'}}>
          <RiCopyrightLine style={{ color: '#6b657a', marginTop: isShortMobile ? '4px' :'0' }}/>
          <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'12px',sm:'14px',}, fontWeight:'500', color: '#6b657a', textAlign:{xs:'left',sm:'left'}}}>
            bsmart 2023 powered by Powersoft Techno Solutions Pte Ltd. all rights reserved.
          </Typography>
      </Box>
      </Box>
      </Box>
    </Box>
  )
}
