import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
    palette: {
      primary: {
        main: '#1976D2', 
        light: '#63a4ff',
        dark: '#004ba0', 
        contrastText: '#ffffff',
      },
      common: {
        white: '#ffffff',
      },
    },
});

export default theme;