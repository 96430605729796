export const preventDatePickerKeyboard = (e)=>{
    e.preventDefault()  
  }

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };