import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnRouteChange = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page with smooth scrolling when the route changes
    console.log('within');
    window.scrollTo({
      top: 0,
    });
  }, [location.pathname]); // Trigger the effect when the pathname changes

  return null; // This component doesn't render anything
};

export default ScrollToTopOnRouteChange;