import * as React from "react";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Box from '@mui/material/Box';
import { wrap } from "popmotion";
import Carousel1 from '../../Assets/home/Carousel1.png';
import Carousel2 from '../../Assets/home/Carousel2.png';
import Carousel3 from '../../Assets/home/Carousel3.png';
import Carousel4 from '../../Assets/home/Carousel4.png';

const images = [Carousel1, Carousel2, Carousel3, Carousel4]
const variants = {
  enter: (direction) => {
    return {
      y: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      y: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const Example = () => {
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };
let mount = true
  useEffect(() => {
    let count = 0;
    setInterval(() => {
      if(!mount) {
        paginate(count)
        count++
      }
    },1800)
     mount = false;
    
  },[])

  return (
    <Box
    sx={{
      minHeight: '200px',
      height:{xs:'250px', sm:'400px',md:'500px'},
      overflow: 'hidden',
      position: 'relative',
    
    }}
  >
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
        style={{position:'absolute', width:'100%' }}
          key={page}
          src={images[imageIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            y: { type: "spring", stiffness: 200, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag="y"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.y, velocity.y);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
    </Box>
  );
};
