import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import Image from '../../Assets/Product/MobileApps/Mobile.png';
import { ReactComponent as CardIcon} from '../../Assets/Product/MobileApps/CardIcon.svg';
import { ReactComponent as WatchVideo } from "../../Assets/home/WatchVideoIcon.svg";
import { ReactComponent as MobileWatchVideo } from "../../Assets/home/MobileWatchVideo.svg";
import { ReactComponent as SliderLogo } from "../../Assets/home/sliderLogo.svg";
import { ReactComponent as Logo1 } from "../../Assets/Product/SoftwareSolution/Logo1.svg";
import { ReactComponent as Card1 } from "../../Assets/Product/SoftwareSolution/Card1.svg";
import { ReactComponent as Card2 } from "../../Assets/Product/SoftwareSolution/Card2.svg";
import { ReactComponent as Card3 } from "../../Assets/Product/SoftwareSolution/Card3.svg";
import { ReactComponent as Card4 } from "../../Assets/Product/SoftwareSolution/Card4.svg";
import { ReactComponent as RectangleIcon } from "../../Assets/Product/SoftwareSolution/RectangleIcon.svg";
import { ReactComponent as TodayIcon } from "../../Assets/Product/SoftwareSolution/TodayIcon.svg";
import { ReactComponent as Barline } from "../../Assets/Product/SoftwareSolution/Barline.svg";
import { ReactComponent as RoundedBar } from "../../Assets/Product/SoftwareSolution/RoundedBar.svg";
import { ReactComponent as CurveChart } from "../../Assets/Product/SoftwareSolution/CurveChart.svg";
import { ReactComponent as LineChart } from "../../Assets/Product/SoftwareSolution/LineChart.svg";
import { ReactComponent as LineChart2 } from "../../Assets/Product/SoftwareSolution/LineChart2.svg";
import { ReactComponent as LineChart3 } from "../../Assets/Product/SoftwareSolution/LineChart3.svg";
import { ReactComponent as LineChart4 } from "../../Assets/Product/SoftwareSolution/LineChart4.svg";
import { ReactComponent as LineChartInfo } from "../../Assets/Product/SoftwareSolution/LineChartInfo.svg";
import { ReactComponent as CurveChart2 } from "../../Assets/Product/SoftwareSolution/CurveChart2.svg";
import { ReactComponent as CurveChart3 } from "../../Assets/Product/SoftwareSolution/CurveChart3.svg";
import { ReactComponent as CurveChart4 } from "../../Assets/Product/SoftwareSolution/CurveChart4.svg";
import SliderOne from '../../Assets/home/sliderIcon1.png';
import SliderEmpty from '../../Assets/home/sliderEmptyIcon.png';
import Carroussel from '../home/Carousel';
import Carousel1 from '../../Assets/home/Carousel1.png';
import Carousel2 from '../../Assets/home/Carousel2.png';
import Carousel3 from '../../Assets/home/Carousel3.png';
import Carousel4 from '../../Assets/home/Carousel4.png';
import Card from '../home/Card';
import { makeStyles } from '@mui/styles';
import theme from '../../utils/theme';

const sliderData = [SliderOne, SliderEmpty, SliderEmpty, SliderEmpty, SliderEmpty, SliderEmpty, SliderEmpty]

let cards = [
    {
      key: 1,
      content: (
        <Card imagen={Carousel1} />
      )
    },
    {
      key: 2,
      content: (
        <Card imagen={Carousel2} />
      )
    },
    {
      key: 3,
      content: (
        <Card imagen={Carousel3} />
      )
    },
    {
      key: 4,
      content: (
        <Card imagen={Carousel4} />
      )
    }
  ];
export default function SoftwareSolution() {
  const [isMobile, setIsMobile] = useState(window.innerWidth<600);
  let barStyle;
  const handleResize = ()=> {
    setIsMobile(window.innerWidth<600);
  }


  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return()=> {
      window.removeEventListener('resize',handleResize)
    }
  },[])
  const CurveCharts = [<CurveChart  style={{ width:'100%', height:'100px'}}/>, <CurveChart2  style={{ width:'100%', height:'100px'}}/>, <CurveChart3  style={{ width:'100%', height:'100px'}}/>, <CurveChart4  style={{ width:'100%', height:'100px'}}/>]
  const lineCharts = [<LineChart  style={{ width:'100%', height:'50px'}}/>, <LineChart2  style={{ width:'100%', height:'50px'}}/>, <LineChart3  style={{ width:'100%', height:'50px'}}/>, <LineChart4  style={{ width:'100%', height:'50px'}}/>]
  const RoundedCharts = [<RoundedBar  style={{width:'100%', height: isMobile ? '80px': '150px', rotate:'45deg'}}/>, <RoundedBar  style={{ width:'100%', height: isMobile ? '80px': '150px', rotate:'180deg'}}/>, <RoundedBar  style={{ width:'100%', height:isMobile ? '80px': '150px', rotate:'90deg'}}/>, <RoundedBar  style={{ width:'100%', height:isMobile ? '80px': '150px', rotate:'360deg'}}/>]
    const AnimatedCurveChart = () => {
        const [currentIconIndex, setCurrentIconIndex] = useState(0);
      
        useEffect(() => {
          const intervalId = setInterval(() => {
            setCurrentIconIndex((prevIndex) => (prevIndex + 1) % CurveCharts.length);
          }, 1000);
      
          return () => {
            clearInterval(intervalId);
          };
        }, []);
      
        return CurveCharts[currentIconIndex]
      };
      const AnimatedLineCharts = () => {
        const [currentIconIndex, setCurrentIconIndex] = useState(0);
      
        useEffect(() => {
          const intervalId = setInterval(() => {
            setCurrentIconIndex((prevIndex) => (prevIndex + 1) % lineCharts.length);
          }, 1000);
      
          return () => {
            clearInterval(intervalId);
          };
        }, []);
      
        return lineCharts[currentIconIndex]
      };
      const AnimatedRoundedBar = () => {
        const [currentIconIndex, setCurrentIconIndex] = useState(0);
      
        useEffect(() => {
          const intervalId = setInterval(() => {
            setCurrentIconIndex((prevIndex) => (prevIndex + 1) % RoundedCharts.length);
          }, 1000);
      
          return () => {
            clearInterval(intervalId);
          };
        }, []);
      
        return RoundedCharts[currentIconIndex]
      };
  
  return (
    <motion.div 
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth :"100%", margin:'0 auto', padding:'8px',paddingBottom:'30px'}}>
        <Box sx={{maxWidth:'90%', margin:'0 auto'}}>
            <Box sx={{display:'flex', width:'100%', gap:{xs:'40px',md:'20px'},my:3, flexDirection:{xs:'column', md:'row'} }}>            

                <Box sx={{width:{xs:'100%',md:'50%'}, display:'flex', alignItems:'center'}}>
                    <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:{xs:'20px',sm:'30px'}}}>

                        <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'22px',sm:'26px',md:'30px'}, fontWeight:'700', color: '#000000', textAlign:{xs:'center',md:'left'}}}>
                        Intuitive software solution
                        </Typography>
                        <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:{xs:'center',md:'left'}}}>
                        Easy to use software with AI-Powered success management platform that helps your business.
                        </Typography>
                        <Box sx={{mt:{xs:0, sm:'-11px'},display:'flex', gap:'20px', flexDirection:{xs:'column', sm:'row'}, alignItems:'center', justifyContent:{xs:'center', md:'start'}}}>
          <Button variant="contained" 
                sx={{maxHeight:'56px',alignSelf:'center', padding:'10px, 16px, 10px, 16px', borderRadius:'10px', backgroundColor:'#2B97D1', 
                '&:hover': {
                  backgroundColor:'#2B97D1'
                },
              }}
                ><Typography sx={{fontFamily:'poppins',  lineHeight:'24px', fontSize:{xs:'13px',sm:'15px',md:'16px'}, fontWeight:'700',  textTransform:'none', p:1}}>Try free trial</Typography></Button>
            <Box sx={{display:{xs:'none',sm:'flex'}, alignItems:'center'}}>
              <IconButton><WatchVideo /></IconButton>
                     
            <Typography sx={{fontFamily:'poppins',  fontSize:'16px', fontWeight:'400'}}>Watch video</Typography>     
            </Box>
            <Box sx={{display:{xs:'flex',sm:'none'}, alignItems:'center'}}>
              <IconButton><MobileWatchVideo /></IconButton>
                     
                <Typography sx={{fontFamily:'poppins',  fontSize:{xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400'}}>Watch video</Typography>
            </Box>
        </Box>
                    </Box>
                </Box>

                <Box sx={{width:{xs:'100%',md:'50%', position:'relative'}, }}>
                    {/* <Box
                    component='img'
                    src={Image}
                    sx={{width:'100%',}}
                    /> */}
                    {/* <CardIcon style={{height:'auto'}}/> */}
                    <Box sx={{paddingBottom:2, }}><SliderLogo /></Box>
                    <Box sx={{display:'flex', }}>
                        <Box  sx={{backgroundColor:'#FFFFFF', display:'flex', flexDirection:'column', gap:{xs:'0', sm:'20px'}, paddingRight:'16px', justifyContent:'space-between'}}>
                        {sliderData.map((item, index) => (
                            <Box
                            key={index}
                            component="img"
                            sx={{ bottom:0, height: {xs:'24.51px',sm:'33.51px'}, width:{xs:'24.51px',sm:'33.51px'}}}
                            alt="Bsmart"
                            src={item}
                        />
                        ))}
                        </Box>
                        <Box sx={{flex:1,  borderRadius:'25.69px', display:'flex', background:{xs:'linear-gradient(0deg, #CCCCCC, #CCCCCC), linear-gradient(0deg, #FFFFFF, #FFFFFF)'}}}>
                    <Box  sx={{flex:1,m:1,backgroundColor:'#FFFFFF', borderRadius:'25.69px',p:{xs:1, md:2}, position:'relative'}}>
                    <Box sx={{ display:'flex', justifyContent:'center'}}>
                            <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:'10px'}}>
                            <Typography sx={{fontFamily:'poppins',  fontSize:{xs:'13px',sm:'15px',md:'16px'}, fontWeight:'700', color:'#1A1B1C'}}>Orders</Typography>
                            <Box sx={{display:'flex',  width:'100%'}}>
                                <Card1 style={{ width:'100%', height:'auto'}}/>
                                <Card2 style={{ width:'100%', height:'auto'}}/>
                                <Card3 style={{ width:'100%', height:'auto'}}/>
                                <Card4 style={{ width:'100%', height:'auto'}}/>
                            </Box>
                            <Box sx={{display:'flex',  width:'100%', justifyContent:'end'}}>
                                <Box sx={{zIndex:1, flex:1, position:{xs:'relative',md:'absolute'}, left:{xs:0,md:'-84px'}, display:'flex', flexDirection:'column', boxShadow:'0px 16.30344009399414px 56.70761489868164px 0px #00000012', backgroundColor:'white', padding:'10px', borderRadius:'15px'}}>
                                    <Box sx={{display:'flex', justifyContent:'space-between',gap:'30px', alignItems:'center', padding:'10px', borderRadius:'15px'}}>
                                        <RectangleIcon style={{width:isMobile ? '100%' :'60px'}}/>
                                        <TodayIcon  style={{width:isMobile ? '100%' :'60px'}}/>
                                    </Box>
                                    <Box sx={{display:'flex', alignItems:'center',gap:'15px'}}>
                                      <AnimatedRoundedBar />
                                        <Barline  style={{ height:'80px'}}/>
                                    </Box>
                                </Box>
                                <Box sx={{display:'flex',  width:'30%'}}>
                                    {/* <CurveChart style={{ width:'100%', height:'auto'}}/> */}
                                    <AnimatedCurveChart />
                                </Box>
                                <Box sx={{display:'flex', width:'30%'}}>
                                    <Box sx={{display:'flex', flexDirection:'column', boxShadow:'0px 16.30344009399414px 56.70761489868164px 0px #00000012', backgroundColor:'white', padding:'10px', borderRadius:'15px'}}>
                                    <Box sx={{display:'flex', justifyContent:'space-between',gap:'30px', alignItems:'center', padding:'10px', borderRadius:'15px'}}>
                                        <RectangleIcon style={{width:'100%', height:'auto'}}/>
                                        <TodayIcon  style={{width:'100%', height:'auto'}}/>
                                    </Box>
                                    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center',gap:'15px'}}>
                                        {/* <LineChart  style={{width:'100%', height:'50px'}}/> */}
                                        <AnimatedLineCharts />
                                        <LineChartInfo  style={{maxWidth:'80px', height:'auto', width:'100%',}}/>
                                    </Box>
                                </Box>
                                </Box>
                                
                            </Box>
       
                            </Box>
                        </Box>
                    </Box>
                </Box>
                        
                    </Box>

                </Box>
            </Box>  
        </Box>   
        
    </motion.div>
  )
}
