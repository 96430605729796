import React,{useState, useEffect} from 'react'
import { Box } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import HomeIcon2 from '../../Assets/home/homeicon2.png';
import { useInView } from "react-intersection-observer";
import bg from "../../Assets/home/bg7.jpg"

export default function Whybsmart() {
    const [hasAnimated, setHasAnimated] = useState(false);
    const control = useAnimation();
    const [ref, inView] = useInView();
    const [rotation, setRotation] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
      const { clientX, clientY, target } = e;
      const { left, top, width, height } = target.getBoundingClientRect();
      const x = ((clientX - left) / width - 0.5) * 2;
      const y = ((clientY - top) / height - 0.5) * 2;
  
      setRotation({ x, y });
    };

    const handleMouseLeave = () => {
      setRotation({ x: 0, y: 0 });
    };

    const boxVariant = {
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
        hidden: { opacity: 0, scale: 0}
    };
      
    const cardVariant = {
        visible: { opacity: 1, scale: 1, rotateY: 0, transition: { duration: 0.5 } },
        hidden: { opacity: 0, scale: 0, rotateY: 180 }
    };
  
    useEffect(() => {
      if (inView && !hasAnimated) {
        control.start('visible');
        setHasAnimated(true);
      }
    }, [control, inView, hasAnimated]);
    
  return (
    <Box sx={{backgroundImage:`url(${bg})`, backgroundPosition:'center', backgroundSize:'cover'}}>
    <Box sx={{ maxWidth:'85%', margin:'20px auto', padding:'8px', mt:3}}>
        <Box  sx={{ display:'center', justifyContent:'center',}}
 
        >
          <motion.div
            className="box"
            ref={ref}
            variants={boxVariant}
            initial="hidden"
            animate={control}
            style={{display:'flex', justifyContent:'center', flexDirection:'column',  transition:'transform 0.5s ease', position:'relative', perspective:'1000px',}}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          >
              <Box sx={{width:{xs:'100%',sm:'80%', md:'70%'}, alignSelf:'center',transition:'transform 0.3s ease',  '&:hover': {transform:'rotateX(20deg)'} }} component='img' src={HomeIcon2}
                style={{
                  transform: `rotateX(${rotation.y * 20}deg) rotateY(${-rotation.x * 20}deg)`,
                }}
              />                
          </motion.div>    
        </Box>
    </Box>
    </Box>
  )
}
