import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography, Tabs, Tab } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { ReactComponent as CustomApps } from "../../Assets/Features/CustomApps.svg";
import { ReactComponent as DriverMonitoring } from "../../Assets/Features/DriverMonitoring.svg";
import { ReactComponent as DriverReports } from "../../Assets/Features/DriverReports.svg";
import { ReactComponent as ProfitTracking } from "../../Assets/Features/ProfitTracking.svg";
import { ReactComponent as SaleMonitoring } from "../../Assets/Features/SaleMonitoring.svg";
import { ReactComponent as StockMonitoring } from "../../Assets/Features/StockMonitoring.svg";
import { ReactComponent as Dashboard } from "../../Assets/Features/Dashboard.svg";
import { ReactComponent as CustomerOrderApp } from "../../Assets/Features/CustomerOrderApp.svg";
import { ReactComponent as SalesDriven } from "../../Assets/Features/SalesDriven.svg";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  link: {
    position: 'relative',
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
      '&::before': {
        transform: 'scaleX(1)',
      },
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: 2,
      bottom: 0,
      left: 0,
      backgroundColor: '#000',
      transform: 'scaleX(0)',
      transition: 'transform 0.3s ease',
    },
  },
});

const featureDetails = [
    {id:1,  icon: <Dashboard />, name:'Dashboard Analisys', description:"Access all the driver reports of deliveries in a single place for each driver."},
    {id:2,  icon: <CustomerOrderApp />, name:'Customer Order App', description:"Monitor all the existing stocks in the warehouse and convert them into required stocks."},
    {id:3,  icon: <SalesDriven />, name:'Sales Driven App', description:"Custom Mobile apps for customer and driver to simplify the user experience."},
    {id:4,  icon: <DriverMonitoring />, name:'Driver Statistics', description:"Track and monitor all the drivers from the system to increase productivity and reduce delays."},
    {id:5,  icon: <DriverReports />, name:'Reports', description:"Manage all the sales and purchases from the customers in a single place in real time."},
    {id:6,  icon: <ProfitTracking />, name:'Profits', description:"Track all the profits and the profits for individual orders and overall sales in the application."},
  ]
export default function AboutCard() {
    const classes = useStyles();

  return (
    <motion.div
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth:'100%' }}
    >   
    <Box sx={{backgroundColor:'#F8FAFC',}}>
        <Box sx={{ maxWidth:'85%', margin:'20px auto', padding:'8px', display:'flex', flexDirection:'column', alignItems:'center', mt:3, gap:'20px'}}>
            <Box >
                <Typography sx={{textAlign:'center', fontFamily:'poppins', lineHeight:'54px', fontSize: {xs:'25px',sm:'30px',md:'36px'}, fontWeight:'700',  color: '#31284C'}}>Features</Typography>
            </Box>
            <Box>
                <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'center'}}>
                Unique features to choose BSmart over other software in the market
                </Typography>
            </Box>
            <Box sx={{display:'flex', justifyContent:{xs:'center',md:'space-between'}, flexWrap:'wrap', mb:3, gap:{xs:'20px', md:0}}}>    
                {featureDetails.map((item, index) => (
                    <Box key={index} sx={{display:'flex', flexDirection:'column', alignItems:'center', minWidth:'280px', maxWidth:{xs:'24%',sm:'28%'}, padding:'24px 12px 24px 12px', boxShadow:'0px 4px 10px 0px #0000001A', borderRadius:'20px', backgroundColor:'#F1FAFF', mt:{xs:'20px',sm:"50px"}, transition:'box-shadow 0.3s ease', '&:hover': {boxShadow:' 0px 12px 20px 5px #0000001A'}}}>
                        <Box>
                            {item.icon}
                        </Box>
                        <Typography sx={{userSelect:'none',mb:1,fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'15px',sm:'16px',md:'18px'}, fontWeight:'600', color: '#31284C', textAlign:'center'}}>
                            {item.name}
                        </Typography>
                        <Typography sx={{userSelect:'none', px:3,fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'15px',sm:'16px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'center'}}>
                            {item.description}
                        </Typography>
                        

                    </Box>
                ))}
            </Box>
        </Box>
    </Box>
    </motion.div>
  )
}
