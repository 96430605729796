import React from 'react'
import { Box, Button, IconButton, Typography, Drawer } from '@mui/material';
import HelpHeader from '../components/Help/HelpHeader';
import HelpAccordion from '../components/Help/HelpAccordion';
import { motion } from 'framer-motion';

export default function Help() {
  return (
    <motion.Box
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opacity:0}}
    >
      <Box sx={{mb:5}}>
        <HelpHeader />
        <HelpAccordion />
      </Box>
    </motion.Box>
  )
}
