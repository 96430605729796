import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { ReactComponent as WatchVideo } from "../../Assets/home/WatchVideoIcon.svg";
import { ReactComponent as MobileWatchVideo } from "../../Assets/home/MobileWatchVideo.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Image1 from '../../Assets/Aboutus/about.jpg';
import { motion } from 'framer-motion';

export default function AboutHeader() {
  const [isScalingUp, setIsScalingUp] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsScalingUp((prev) => !prev);
    }, 600);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    return () => clearInterval(intervalId);
  }, []);
  return (
    <motion.div 
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth:'85%', margin:'0 auto', padding:'8px'}}>
      <Box sx={{display:'flex', gap:'10px', width:'100%', flexDirection:{xs:'column',md:'row'}}}>
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'start', mt:3, gap:'20px', width:{xs:'100%',md:'50%'}}}>
          <Box  sx={{display:'flex', flexDirection:'column'}}>
          <Typography sx={{textAlign:'left', fontFamily:'poppins', lineHeight:'54px', fontSize: {xs:'1rem',sm:'1.25rem',md:'1.25rem'}, fontWeight:'800',  color: '#2B97D1'}}>ABOUT US</Typography>
          <Typography sx={{textAlign:'left', fontFamily:'poppins', lineHeight:'54px', fontSize: {xs:'25px',sm:'30px',md:'36px'}, fontWeight:'800',  color: '#31284C'}}>Creating smarter solutions for a better tomorrow</Typography>
          </Box>
          <Box>
          <Typography sx={{mb:1,fontFamily:'poppins', lineHeight:'31px', letterSpacing:'0.02em', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left',}}>
          Bsmart is a Cohesive Application which seamlessly
          integrates all in one smart solution predominantly made
          for overall operations by providing tools for tasks such as
          managing orders, Profit Analysis Tracker, tracking
          inventory levels and locations, shipments, and automating
          various processes with Mobile Application feature to
          streamline, manage and optimize the work effortlessly.
            </Typography>
        
          </Box>

        </Box> 
        <Box sx={{width:{xs:'100%',md:'50%'}}}>
              <LazyLoadImage 
              // component='img'
                src={Image1}
                effect="blur"
                style={{width:'100%'}}
              />
        </Box>
      </Box>

    </motion.div>
  )
}
