import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import Image from '../../Assets/Product/BusinessSolution/Image.png';
import { ReactComponent as CardIcon} from '../../Assets/Product/BusinessSolution/CardIcon1.svg';

export default function BusinessSolution() {
    const [rotation, setRotation] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
      const { clientX, clientY, target } = e;
      const { left, top, width, height } = target.getBoundingClientRect();
      const x = ((clientX - left) / width - 0.5) * 2;
      const y = ((clientY - top) / height - 0.5) * 2;
  
      setRotation({ x, y });
    };

    const handleMouseLeave = () => {
      setRotation({ x: 0, y: 0 });
    };
  
  return (
    <motion.div 
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth :"100%", margin:'0 auto', padding:'8px', marginBottom:'20px'}}>
        <Box sx={{maxWidth:'90%', margin:'0 auto'}}>
            <Box sx={{display:'flex', width:'100%', gap:'20px',my:3, flexDirection:{xs:'column', md:'row'} }}>            
                
            <Box sx={{width:{xs:'100%',md:'50%'}, display:'flex', justifyContent:'center', transition:'transform 0.5s ease', position:'relative', perspective:'1000px',}}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
            >
                    <Box
                    component='img'
                    src={Image}
                    sx={{width:'100%',borderRadius:'20px',transition:'transform 0.3s ease',  '&:hover': {
                        transform:'rotateX(20deg)'
                      }}}
                      style={{
                        transform: `rotateX(${rotation.y * 20}deg) rotateY(${-rotation.x * 20}deg)`,
                      }}
                    />

            </Box>
                <Box sx={{width:{xs:'100%',md:'50%'}, display:'flex', alignItems:'center', justifyContent:'end'}}>
                    <Box sx={{width:{xs:'100%',md:'80%'}, display:'flex', flexDirection:'column', gap:{xs:'20px',sm:'30px'}}}>
                        <Typography sx={{fontFamily:'poppins', lineHeight:'24px', letterSpacing:'0.2em', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left'}}>
                        PRODUCT ACTION
                        </Typography>
                        <Typography sx={{fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'22px',sm:'26px',md:'30px'}, fontWeight:'700', color: '#000000', textAlign:'left'}}>
                        Try the Business solution
                        </Typography>
                        <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left'}}>
                        See the product to view the orders and managing orders from warehouse customers in a streamlined order.
                        </Typography>
                        <Button variant="contained" 
                        sx={{maxHeight:'56px',alignSelf:'start', padding:'10px, 16px, 10px, 16px', borderRadius:'10px', backgroundColor:'#2B97D1', 
                        '&:hover': {
                        backgroundColor:'#2B97D1'
                        },
                        }}
                        ><Typography sx={{fontFamily:'poppins',  lineHeight:'24px', fontSize:{xs:'13px',sm:'15px',md:'16px'}, fontWeight:'700',  textTransform:'none', p:1}}>Try free trial</Typography></Button>
                    </Box>
                </Box>
            </Box>  
        </Box>   
        
    </motion.div>
  )
}
