import React from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { motion } from 'framer-motion';
import { Box, Button, IconButton, Typography, Drawer  } from '@mui/material';
import { ReactComponent as SupportIcon} from '../../Assets/Support/SupportIcon.svg';
import { ReactComponent as Arrow} from '../../Assets/Support/Arrow.svg';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  flexDirection:'row',

  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
 
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),

}));

const accordionData = [{id:1,question:"How can You Make Your Small Business More Efficient with Bsmart?", description:"Bsmart is a technology solution designed to optimize and streamline various end to end operations. For small businesses, it offers benefits such as improved order management, payment and financial Analysis, sales and profit Indicators, automated delivery management, inventory management, order fulfillment efficiency, labor optimization, and better overall visibility to Accelerate business growth processes."},
{id:2,question:"How can your business benefit from Bsmart?", description:['Enhanced Order Accuracy', 'Optimized resource utilization', 'Improved Task Management','Value-based Pricing','Efficient Order Fulfillment','Reduce downtime, Realtime Tracking & Visibility','Efficient reporting & Analysis','Scalable & Adaptable Solutions.']},
{id:3,question:"How Bsmart is different from others?", description:['Tailor made Functionality','Business Scalability','Ease of Integration','Adaptability to Industry Requirements','User-Friendly Interface','Real-Time Visibility.']},
{id:4,question:"How Bsmart plan is suitable for my business?", description:['Bsmart is suitable for all Business Size and reduce complexity.', 'Integration capabilities','Unique and easy functionality with features','Customization options','User-Friendly Interface','Mobile Accessibility','Support and Training','Custom Cost Structure','Security Measures','Customer Reviews and References']},
{id:5,question:"Why Bsmart is the Best product for Inventory Management?", description:['Real-Time Visibility', 'Accuracy and Precision', 'Optimized Storage and Retrieval','Order Fulfillment Efficiency','Demand Forecasting','Traceability and Compliance','Reporting and Analytics']},
{id:6,question:"Comes With Barcode Scanner?", description:"No."},
{id:7,question:"Track Your Inventory on the Phone?", description:"Yes"},
{id:8,question:"Create Sales/Purchase order?", description:"Yes"},
{id:9,question:"Can Help Track Inventory Across Locations?", description:"Yes"},
{id:10,question:"How can You Keep a Check on Your Stock Through the Bsmart App?", description:"Yes"},
{id:11,question:"Think About the Cost of The Software?", description:"You are at the right place to experience the transformation: Request a live demo now! Let's launch your streamlined business in just 3 days with our all-in-one solution."},
]
export default function HelpAccordion() {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <motion.div
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 2}}
    style={{backgroundColor:'F7F7F7'}}
    >
    <>
      <Box sx={{backgroundColor:'#fff'}}>
        <Box sx={{width:'90%',margin:'0 auto',mt:5, display:'flex', flexDirection:'column', gap:'20px', pb:12 }}>
        {accordionData.map((item, index) => (
            <Accordion key={index} expanded={expanded === `panel${item.id}`} onChange={handleChange(`panel${item.id}`)}>
            <AccordionSummary sx={{flexDirection:'row',}} aria-controls={`panel${item.id}d-content`} id={`panel${item.id}d-header`}>
              <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'18px',sm:'20px',md:'20px'}, fontWeight:'600', color: '#31284C'}}>{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              { Array.isArray(item.description) ? (
                <ul>
                  {item.description.map((data, index) => (
                      <li key={index}>{data}</li>
                  ))}
                  </ul>
              ) : (
                <Typography sx={{marginLeft:'10px', fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C'}}>
                {item.description}
                </Typography>
              )

              }
            </AccordionDetails>
          </Accordion>
        ))}

            {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary sx={{flexDirection:'row'}}  aria-controls="panel2d-content" id="panel2d-header">
              <Typography sx={{padding:'0',fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'20px',sm:'22px',md:'22px'}, fontWeight:'600', color: '#31284C'}}>What are the pricing options?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{marginLeft:'10px', fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C'}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                  malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                  sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                  sit amet blandit leo lobortis eget.
                </Typography>
              </AccordionDetails>
            </Accordion> */}
            {/* <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary sx={{flexDirection:'row'}}  aria-controls="panel3d-content" id="panel3d-header">
              <Typography sx={{padding:'0', fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'20px',sm:'22px',md:'22px'}, fontWeight:'600', color: '#31284C', textAlign:'center'}}>What is BSmart?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{marginLeft:'10px', fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C'}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                  malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                  sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                  sit amet blandit leo lobortis eget.
                </Typography>
              </AccordionDetails>
            </Accordion> */}
        </Box>

    </Box>
    <Box sx={{  backgroundColor:'#F7F7F7', marginBottom:'-39px' }}>
            <Box sx={{width:'90%', margin:'0 auto', py:3}}>
            <Box sx={{backgroundColor:'#fff', p: "20px 24px", border:'0.02px solid #b5b5b6', borderRadius:'20px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                  <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'15px',sm:'19x',md:'20px'}, fontWeight:'600', color: '#333333', textAlign:'left'}}>
                  Still having difficulty?
                  </Typography>
                  <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'12px',sm:'16px',md:'17px'}, fontWeight:'600', color:'#333333'}}>
                    Get Support
                  </Typography>
                </Box>
                    <Button variant='text' color="secondary" sx={{borderRadius:'20px'}} startIcon={<SupportIcon />} endIcon={<Arrow />}>
                        <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'12px',sm:'15px',md:'16px'}, fontWeight:'600', }}>
                        Get Support
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
  </>

    </motion.div>
  )
}
