import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography, Tabs, Tab } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { PropTypes } from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ReactComponent as CustomApps } from "../../Assets/Features/CustomApps.svg";
import { ReactComponent as DriverMonitoring } from "../../Assets/Features/DriverMonitoring.svg";
import { ReactComponent as DriverReports } from "../../Assets/Features/DriverReports.svg";
import { ReactComponent as ProfitTracking } from "../../Assets/Features/ProfitTracking.svg";
import { ReactComponent as SaleMonitoring } from "../../Assets/Features/SaleMonitoring.svg";
import { ReactComponent as StockMonitoring } from "../../Assets/Features/StockMonitoring.svg";
import { ReactComponent as Dashboard } from "../../Assets/Features/Dashboard.svg";
import { ReactComponent as CustomerOrderApp } from "../../Assets/Features/CustomerOrderApp.svg";
import { ReactComponent as SalesDriven } from "../../Assets/Features/SalesDriven.svg";
import ReactPlayer from 'react-player';
import { useInView } from "react-intersection-observer";
import FeatureData from './featureData';

const useStyles = makeStyles((theme) => ({
    customIndicator: {
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '50%', 
        transform: 'translateX(-50%)',
        width: '50%',
        borderBottom: `4px solid #2B97D1`,
      },
    },
    customTabLabel: {
        fontSize: '26px', 
        color: '#31284C',
        textTransform:'none'  
      },
  }));

  const tabValue = [
    {id:1, name:'Dashboard Analysis', icon: <Dashboard />},
    {id:2, name:'Customer Order App', icon: <CustomerOrderApp />},
    {id:3, name:'Sales Driven App', icon: <SalesDriven />},
    {id:4, name:'Driver Statistics', icon: <DriverMonitoring />},
    {id:5, name:'Reports', icon: <SaleMonitoring />},
    {id:6, name:'Profits', icon: <ProfitTracking />}
]

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Box
      sx={{}}
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0, pl:0, pb:0, overflow:'hidden' }}>
            <Typography sx={{display:'flex', flexDirection:{xs:'column',md:'row'}}}>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const textVariant = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', damping: 10, stiffness: 100,  delay: 0.3, duration:1  } 
    },
    hidden: { opacity: 0, y: 20 }
  };
  const textVariant2 = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', damping: 10, stiffness: 100,  delay: 0.8, duration:1  } 
    },
    hidden: { opacity: 0, y: 20 }
  };

  const featureDetails = [
    {id:1, name:'Dashboard Analysis', description:"Access all the driver reports of deliveries in a single place for each driver."},
    {id:2, name:'Customer Order App', description:"Monitor all the existing stocks in the warehouse and convert them into required stocks."},
    {id:3, name:'Sales Driven App', description:"Custom Mobile apps for customer and driver to simplify the user experience."},
    {id:4, name:'Driver Statistics', description:"Track and monitor all the drivers from the system to increase productivity and reduce delays."},
    {id:5, name:'Reports', description:"Manage all the sales and purchases from the customers in a single place in real time."},
    {id:6, name:'Profits', description:"Track all the profits and the profits for individual orders and overall sales in the application."},
  ]

  
export default function FeatureTabs() {
    const theme = useTheme();
    const classes = useStyles();
    const [isMobile, setIsMobile] = useState(window.innerWidth<772);
    const [isTab, setIsTab] = useState(window.innerWidth<903);
    const [value, setValue] = React.useState(0);
    var videoTag;

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };
    const handleResize = ()=> {
      setIsMobile(window.innerWidth<772);
      setIsTab(window.innerWidth<903);
      if(window.innerWidth<903) {
        if(videoTag?.style.borderTopRightRadius == '0px') {
          videoTag.style.borderTopRightRadius = '20px';
        }        
      } else if(window.innerWidth>903){
        if(videoTag?.style.borderTopRightRadius == '20px') {
          videoTag.style.borderTopRightRadius = '0px';
        }
      }
    }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    

    return()=> {
      window.removeEventListener('resize',handleResize)
    }
  },[])

  const AnimationText = ({Name, Description}) => {
    const [hasAnimated, setHasAnimated] = useState(false);
    const control = useAnimation();
    const [ref, inView] = useInView();

  
    useEffect(() => {
      if (inView && !hasAnimated) {
        control.start('visible');
        setHasAnimated(true);
      }
    }, [control, inView, hasAnimated]);
  
    return (

      <motion.div sx={{ width: '100%', display: 'flex', position: 'absolute', flexDirection: 'column', gap: '20px', zIndex: '1',  justifyContent: 'center',borderTopRightRadius: '20px',height: '100%',width: '100%',backgroundColor: '#1f3d4738', display:'none',}}
        ref={ref}
        variants={textVariant}
        initial="hidden"
        animate={control}
      >
        <Typography sx={{padding: '0 10px',alignSelf: 'start',fontFamily: 'poppins',lineHeight: '24px', fontSize: { xs: '18px', sm: '20px', md: '25x' },fontWeight: '700',color: '#31284C',textAlign: 'left'}}
        >
          {Name}
        </Typography>
        <Typography
          sx={{padding: '0 10px',alignSelf: 'start',fontFamily: 'poppins',lineHeight: '34px',fontSize: { xs: '15px', sm: '16px', md: '16x' },fontWeight: '600',color: '#31284C',textAlign: 'left',mt:'10px' }}
        >
          {Description}
        </Typography>
      </motion.div>

    );
  };
  const handleReady = (player) => {
    const videoElement = player.getInternalPlayer(); 

    if (videoElement) {
      videoTag = videoElement
      videoElement.style.objectFit = 'fill';
      videoElement.style.borderTopLeftRadius = "20px";
      videoElement.style.borderTopRightRadius = "0";
      videoElement.style.outline = '0';
      videoElement.style.border = 'none';
      videoElement.addEventListener('focus', function () {
        videoElement.style.outline = '0';
        videoElement.style.border = 'none';
      });
      if(isTab) {
        videoElement.style.borderTopRightRadius = "20px";
      }
    }
  }
  
  return (
    <motion.div
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    >
      <>
    <Box  sx={{borderRadius:'12px', display:'flex', flexDirection:'column',margin:'0 auto', my:2, width:'80%', paddingTop:2,  alignSelf:'center', borderRadius:'12.65px', }}>
          <Box sx={{ bgcolor: 'background.paper'}}>
     
            <SwipeableViews
            sx={{px:2}}
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
            >
            {featureDetails.map((item, index) => (
                <TabPanel key={index} value={value} index={index} dir={theme.direction}>
                <Box sx={{width:{xs:'100%',md:'70%'}, position:'relative', paddingTop:'46.25%', height:{xs:'80px', sm:'100%'}}}>
                    <ReactPlayer style={{position:'absolute', top:0, left:0, objectFit:'cover', width:'100%', height:'100%'}} 
                    playing={true}
                    
                    loop
                    // url="https://vjs.zencdn.net/v/oceans.mp4" // https://www.youtube.com/shorts/7P-3013JFc8
                    url='video5.mp4'
                    className='react-player'
                    onReady={handleReady}
                    width='100%'
                    height='100%'
                    maxWidth='400px'
                    playsinline
                    muted 
                    />
                    {/* <video>
                      <source src='video1.mp4' width='100%' height='100%'  type='video/mp4' />
                    </video> */}
                    {/* <Box sx={{display:'flex', flexDirection:'column', gap:'20px',justifyContent:'center', padding:'20px',top:'22px',bottom:'60px', width:'30%', backgroundColor:'#1f3d4738', position:'absolute', borderBottomRightRadius:'20px'}}>
                      <Typography sx={{alignSelf:'start', fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'15px',sm:'20px',md:'25x'}, fontWeight:'500', color: '#fff', textAlign:'left'}}>Driver Reports</Typography>
                      <Typography sx={{alignSelf:'start', fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'16px',sm:'16px',md:'16x'}, fontWeight:'500', color: '#fff', textAlign:'left'}}>
                      Access all the driver reports of deliveries in a single place for each driver.
                      </Typography>
                    </Box> */}
                </Box>
                {/* linear-gradient(to bottom, white, #808080) */}
                <Box sx={{display:'none',width: {xs:'100%',md:'30%'}, position:'relative', backgroundColor:'#F1FAFF', display:'flex', alignItems:'center', padding:{xs:'10px 0 10px 0', md:0},borderTopRightRadius:{xs:'0', md:'20px'}}}> 
                  {/* <Box sx={{width:'100%',display:'flex', position:'absolute', flexDirection:'column', gap:'20px', zIndex:'1',justifyContent:'center', borderTopRightRadius:'20px', height:'100%',width:'100%',  backgroundColor:'#1f3d4738'}}>
                      <Typography sx={{padding:'0 10px', alignSelf:'start', fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'15px',sm:'20px',md:'25x'}, fontWeight:'500', color: '#fff', textAlign:'left'}}>Driver Reports</Typography>
                      <Typography sx={{padding:'0 10px', alignSelf:'start', fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'16px',sm:'16px',md:'16x'}, fontWeight:'500', color: '#fff', textAlign:'left'}}>
                      Access all the driver reports of deliveries in a single place for each driver.
                      </Typography>
                  </Box> */}
                  <AnimationText Name={item.name} Description={item.description}/>
                </Box>
            </TabPanel>
            ))
            
            }

            {/* <TabPanel value={value} index={1} dir={theme.direction}>
                Item Two
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
                Item Three
            </TabPanel> */}

            </SwipeableViews>
            <Box sx={{display:'flex', justifyContent:'space-between', backgroundColor:'#F1FAFF', overflow:'scroll none',
            '& .MuiTab-root': {
                textTransform:'none'
            }
            }}>
                <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="green"
                textColor="#31284C"
                variant={isMobile ? 'scrollable' : 'standard'}
                scrollButtons={isMobile}
                allowScrollButtonsMobile={isMobile}
                aria-label="scrollable force tabs example"
                classes={{ indicator: classes.customIndicator }}
                sx={{flex:1}}
            >
            {tabValue.map((item, index) => (
                <Tab key={index} label={item.name} icon={item.icon} {...a11yProps(index)}
                sx={{flex:1,
                    '&.Mui-selected': {
                      backgroundColor:'#E3F5FF',
                    },
                    '&.MuiTab-root': {
                      fontSize:{xs:'13px', sm:'14px'},
                      minWidth:'100px',
                      height:{xs:'84px', md:"99px"}
                    }
                  }}
                classes={{ label: classes.customTabLabel }}
                />
            ))}
            </Tabs>

            </Box>
        </Box>
    </Box>
    <FeatureData tabValue={value}/>
    </>
    </motion.div>
  )
}
