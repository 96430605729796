import React, {useState, useEffect, useRef} from 'react'
import { Box, Button, IconButton, Typography, Tabs, Tab } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { makeStyles } from '@mui/styles';
import MobileApp from '../../Assets/Solutions/MobileApp.png';
import Time from '../../Assets/Solutions/Time.png';
import CustomReports from '../../Assets/Solutions/CustomReports.png';
import DriverLocation from '../../Assets/Solutions/DriverLocation.png';
import DetailImage from '../../Assets/Solutions/DetailImage.png';
import DetailImage2 from '../../Assets/Solutions/DetailImage2.jpg';

  const images = [
    {id:1, name:'Dedicated mobile ', name2:'application', img:MobileApp, isHover:false},
    {id:2, name:'Save processing ', name2:'time', img:Time,isHover:false},
    {id:3, name:'Custom ', name2:'reports', img:CustomReports,isHover:false},
    {id:4, name:'Driver Live ',  name2:'Location',  img:DriverLocation,isHover:false}
]

const descriptions = [
    {id:1, name:'Dedicated mobile application', description:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.", img:DetailImage},
    {id:2, name:'Save processing time', description:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.", img:DetailImage2},
    {id:3, name:'Custom reports', description:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.", img:DetailImage},
    {id:4, name:'Driver Live Location', description:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.", img:DetailImage2},
]

export default function SolutionCards() {
    const [descriptionState, setDescriptionState] = useState();
    const [imageData, setImages] = useState(images);
    const [rotation, setRotation] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
      const { clientX, clientY, target } = e;
      const { left, top, width, height } = target.getBoundingClientRect();
      const x = ((clientX - left) / width - 0.5) * 2;
      const y = ((clientY - top) / height - 0.5) * 2;
  
      setRotation({ x, y });
    };

    const handleMouseLeave = () => {
      setRotation({ x: 0, y: 0 });
    };
  

    const showFeature = (id) => {
        const filteredData = descriptions.filter((item) => item.id == id)
        setDescriptionState(filteredData);
        setTimeout(() => {
        document.getElementById("ids")?.scrollIntoView({ behavior: 'smooth',block: "center"});

        },100)
    }

    const handlehover = (id) => {
        setImages((prev) => prev.map((item) => {
            if(item.id == id) {
                return {...item, isHover: true}
            }
            return item
        }))
    }

    const handleMouseout = (id) => {
        setImages((prev) => prev.map((item) => {
            if(item.id == id) {
                return {...item, isHover: false}
            }
            return item
        }))
    }
  return (
    <motion.div
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 2}}
    >
    <Box sx={{width:'90%',margin:'0 auto', }}>
    <Box  sx={{ display:'flex',  justifyContent:{xs:'center',sm:'space-between'}, flexWrap:'wrap',  my:2, py:2, }}>
        {imageData?.map((item, index) => (
            <Box key={index} onClick={()=> showFeature(item.id)} onMouseOver={()=>handlehover(item.id)} onMouseOut={()=>handleMouseout(item.id)}sx={{position:'relative', width:'250px', height:'320px', overflow:'hidden', marginRight:'10px',marginTop:'20px', border:'0.63px solid #2B97D1',  borderRadius:'12.65px', boxShadow: {xs:'0px 6.322751045227051px 6.322751045227051px 0px #2B97D126'}}}>
                <Box
                component='img'
                src={item.img}
                sx={{width:'100%', height:'100%', objectFit:'cover'}}
                />
                {/* <a href={`#${item.id}`}> */}
                    <Box sx={{width:'100%', height:'100%', position:'absolute', display:'flex', flexDirection:'column', backdropFilter:'blur(10px)', cursor:'pointer', transition:'1s', bottom:'-245px', bottom : item.isHover ? '0' : '-245px',  right:0,  backgroundColor:'#1f3d4738', display:'flex',}}>
                    {/* <Box sx={{backdropFilter:'blur(5px)', position:'absolute', }}> */}
                    <Box sx={{display:'flex', flexDirection:'column',height:'68px', justifyContent:'center', paddingLeft:'10px'}}>
                        <Typography sx={{alignSelf:'start', fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'15px',sm:'20px',md:'25x'}, fontWeight:'500', color: '#fff', textAlign:'left'}}>
                                {item.name}
                        </Typography>   
                        <Typography sx={{alignSelf:'start',fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'15px',sm:'20px',md:'25x'}, fontWeight:'500', color: '#fff', textAlign:'left'}}>
                            {item.name2}
                        </Typography>                     
                    </Box>
                        {/* <Typography sx={{height:'68px',alignSelf:'start', padding:'0 10px 0 10px',fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'15px',sm:'20px',md:'25x'}, fontWeight:'500', color: '#fff', textAlign:'left'}}>
                            {item.name}
                        </Typography> */}
                        <ul style={{color:'white', fontFamily:'poppins',fontSize:'14px', }}>
                            <li>Driver & Customer App</li>
                            <li>Available on Android & IOS</li>
                            <li>Efficient Service</li>
                            <li>Save your time</li>
                        </ul>
                        {/* <Typography sx={{ padding:'0 10px 10px 10px',backdropFilter:'blue(5px)',fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'16px',sm:'16px',md:'16x'}, fontWeight:'400', color: '#fff', textAlign:'left'}}></Typography> */}
                    {/* </Box>  */}
                </Box>
                {/* </a> */}
            </Box>
        ))}
    </Box>

    <Box id='ids'>
    {descriptionState?.length > 0 && descriptionState?.map((item, index) => (
            <motion.div
            key={item.id}
            initial={{opacity:0,scale:0.5}}
            animate={{opacity:1,scale:1}}
            // exit={{opacity:0}}
            transition={{delay:0.3, duration: 1}}
            style={{marginBottom:'16px'}}
            >
        <Box  sx={{mt:5,display:'flex', gap:'20px', width:'100%', flexDirection:{xs:'column', sm:'row'}}} >
            <Box sx={{display:'flex', flexDirection:'column',width:{xs:'100%',sm:'50%'}, alignItems:{xs:'center',sm:'start'}, justifyContent:'center', alignSelf:{xs:'center'}}}>
                <Typography  sx={{alignSelf:'start', padding:'0 10px 10px 10px',fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'25px',sm:'30px',md:'35x'}, fontWeight:'500', color: '#31284C', alignSelf:{xs:'center',sm:'start'}, textAlign:{xs:'center',sm:'left'}}}>
                    {item.name}
                </Typography>
                <ul style={{color:'#31284C', fontFamily:'poppins',}}>
                    <li style={{ fontSize:'16px'}}>Driver & Customer App</li>
                    <li  style={{ fontSize:'16px'}}>Available on Android & IOS</li>
                    <li  style={{ fontSize:'16px'}}>Efficient Service</li>
                    <li  style={{ fontSize:'16px'}}>Save your time</li>
                    <li  style={{ fontSize:'16px'}}>Driver & Customer App</li>
                </ul>
                
            </Box>
            <Box sx={{width:{xs:'100%',sm:'50%'},  alignSelf:{xs:'center'}, transition:'transform 0.5s ease', position:'relative', perspective:'1000px',}}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
            >
                <Box
                    component='img'
                    src={item.img}
                    sx={{width:'100%', height:'100%',  objectFit:'cover', transition:'transform 0.3s ease',  '&:hover': {
                        transform:'rotateX(20deg)'
                      }}}
                    style={{
                    transform: `rotateX(${rotation.y * 20}deg) rotateY(${-rotation.x * 20}deg)`,
                    }}
                    />
                </Box>
        </Box>
    </motion.div>

    ))}
    </Box>
    </Box>
    </motion.div>
  )
}
