import React,{useState, useEffect} from 'react'
import { Box, Typography } from '@mui/material';
import { motion, useAnimation, AnimatePresence  } from 'framer-motion';
import { ReactComponent as Customer } from "../../Assets/home/Customer/Customer.svg"
import { ReactComponent as Satisfaction } from "../../Assets/home/Customer/Satisfaction.svg"
import { ReactComponent as OrderOptions } from "../../Assets/home/Customer/OrderOptions.svg"
import { ReactComponent as DisplayApp } from "../../Assets/home/Customer/DisplayApp.svg"
import { ReactComponent as Tracking } from "../../Assets/home/Customer/Tracking.svg"
import { ReactComponent as Deliveries } from "../../Assets/home/Customer/Deliveries.svg"
import { ReactComponent as Reports } from "../../Assets/home/Customer/Reports.svg"
import { ReactComponent as Payment } from "../../Assets/home/Customer/Payment.svg"
import { ReactComponent as Notification } from "../../Assets/home/Customer/Notification.svg"
import { useInView } from "react-intersection-observer";

export default function BusinessMap() {
    const [isTab, setIsTab] = useState(window.innerWidth<960);
    const [hasAnimated, setHasAnimated] = useState(false);
    const control = useAnimation();
    const [ref, inView] = useInView();

    const boxVariant = {
        visible: { opacity: 1, scale:1,  transition: { duration: 0.5,   } },
        hidden: { opacity: 0, scale: 0, }
    };

    useEffect(() => {
        if (inView && !hasAnimated) {
          control.start('visible');
          setHasAnimated(true);
        }
      }, [control, inView, hasAnimated]);

      const handleResize = ()=> {
        setIsTab(window.innerWidth<960);
      }
      console.log('isTab ',isTab);
      useEffect(() => {
        window.addEventListener('resize', handleResize)
        
    
        return()=> {
          window.removeEventListener('resize',handleResize)
        }
      },[])
      

  return (
    <motion.div 
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth:'760px', margin:'0 auto', padding:'8px', }}>
        <Box sx={{mb:{xs:10,md:'230px'}, width:'100%', padding:{xs:'0',md:'1rem'}}}>
         <Box sx={{width:'100%', maxWidth:'760px', margin:'0 auto', position:'relative', height:  isTab ? 'unset' : '500px', gap:'20px', display:{xs:'flex'}, flexDirection:'column'}}>
         <motion.div
                className="box"
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                exit="hidden"
                animate={control}>
                 <Box sx={{display:'flex', justifyContent:'center', mt:{xs:3, md:0}}}>
                <Box sx={{position: isTab ? 'unset' : 'absolute', top: isTab ? 'unset' :'50%', left: isTab ? 'unset' :'50%', transform: isTab ? 'unset' :'translate(-50%, 50%)', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', maxWidth:'220px', alignSelf:isTab ? 'center' : 'unset'}}>
                    <Box>
                        <Customer/>
                    </Box>
                    <Typography  sx={{alignSelf:'start', padding:'0 10px 10px 10px',fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'16px',sm:'20px',md:'20px'}, fontWeight:'500', color: '#31284C', alignSelf:{xs:'center',md:'start'}, textAlign:'left'}}>
                        Business
                    </Typography>
                </Box>
                </Box>   
                    <Box sx={{display:'flex', flexDirection: {xs:'column', sm:'row'}, justifyContent:'center', alignItems:'center', gap: {xs:'0', sm:'50px', md:'0'} }}>
                        <Box sx={{mb:{xs:3, sm:0}}}>
                            <Box sx={{position:isTab ? 'unset' : 'absolute',left: isTab ? 'unset' : '130px',display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center', minWidth:'200px', maxWidth:{xs:'200px', md:'28%'},   height:'120px', boxShadow:'0px 5px 7px 11px #0000001A', borderRadius:'20px', backgroundColor:'#fff', mt:{xs:'20px',sm:"50px"}, transition:'box-shadow 0.3s ease', '&:hover': {boxShadow:' 0px 20px 20px 5px #0000001A'}}}>
                                <Satisfaction style={{width:'30px', height:'30px'}}/>
                                <Typography  sx={{alignSelf:'start', fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'14px',sm:'15px',md:'15px'}, fontWeight:'500', color: '#31284C', alignSelf:'center', textAlign:'center'}}>
                                Customer Satisfaction
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{mb:{xs:3, sm:0}}}>
                            <Box sx={{position: isTab ? 'unset' :'absolute',right: isTab ? 'unset' : '130px',display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center', minWidth:'200px', maxWidth:{xs:'200px', md:'28%'},   height:'120px', boxShadow:'0px 5px 7px 11px #0000001A', borderRadius:'20px', backgroundColor:'#fff', mt:{xs:'20px',sm:"50px"}, transition:'box-shadow 0.3s ease', '&:hover': {boxShadow:' 0px 20px 20px 5px #0000001A'}}}>
                                <OrderOptions style={{width:'30px', height:'30px'}}/>
                                <Typography  sx={{alignSelf:'start', fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'14px',sm:'15px',md:'15px'}, fontWeight:'500', color: '#31284C', alignSelf:'center', textAlign:'center'}}>
                                Flexible order options
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                
                <Box sx={{display:'flex', mt: isTab ? 0 :  20, flexDirection: {xs:'column', sm:'row'}, justifyContent:'center', alignItems:'center', gap: {xs:'0', sm:'50px', md:'0'}}}>
                    <Box sx={{mb:{xs:3, sm:0}}}>
                            <Box sx={{position: isTab ? 'unset' : 'absolute',left: isTab ? 'unset' : '10px',display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center', minWidth:'200px', maxWidth:{xs:'200px', md:'28%'},   height:'120px', boxShadow:'0px 5px 7px 11px #0000001A', borderRadius:'20px', backgroundColor:'#fff', mt:{xs:'20px',sm:"50px"}, transition:'box-shadow 0.3s ease', '&:hover': {boxShadow:' 0px 20px 20px 5px #0000001A'}}}>
                                <Notification style={{width:'30px', height:'30px'}}/>
                                <Typography  sx={{alignSelf:'start', fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'14px',sm:'15px',md:'15px'}, fontWeight:'500', color: '#31284C', alignSelf:'center', textAlign:'center'}}>
                                Real-time Notifications
                                </Typography>
                            </Box>
                    </Box>
                    <Box sx={{mb:{xs:3, sm:0}}}>
                            <Box sx={{position: isTab ? 'unset' :'absolute',right:  isTab ? 'unset' :'10px',display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center', minWidth:'200px', maxWidth:{xs:'200px', md:'28%'},   height:'120px', boxShadow:'0px 5px 7px 11px #0000001A', borderRadius:'20px', backgroundColor:'#fff', mt:{xs:'20px',sm:"50px"}, transition:'box-shadow 0.3s ease', '&:hover': {boxShadow:' 0px 20px 20px 5px #0000001A'}}}>
                                <DisplayApp style={{width:'30px', height:'30px'}}/>
                                <Typography  sx={{alignSelf:'start', fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'14px',sm:'15px',md:'15px'}, fontWeight:'500', color: '#31284C', alignSelf:'center', textAlign:'center'}}>
                                Efficiently packing through display app
                                </Typography>
                            </Box>
                    </Box>
                </Box>
                <Box sx={{display:'flex', mt: isTab ? 0 : 20, flexDirection: {xs:'column', sm:'row'}, justifyContent:'center', alignItems:'center', gap: {xs:'0', sm:'50px', md:'0'}}}>
                    <Box sx={{mb:{xs:3, sm:0}}}>
                                <Box sx={{position:  isTab ? 'unset' : 'absolute',left: isTab ? 'unset' :'10px',display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center', minWidth:'200px',  maxWidth:{xs:'200px', md:'28%'},   height:'120px', boxShadow:'0px 5px 7px 11px #0000001A', borderRadius:'20px', backgroundColor:'#fff', mt:{xs:'20px',sm:"50px"}, transition:'box-shadow 0.3s ease', '&:hover': {boxShadow:' 0px 20px 20px 5px #0000001A'}}}>
                                    <Payment style={{width:'30px', height:'30px'}}/>
                                    <Typography  sx={{alignSelf:'start', fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'14px',sm:'15px',md:'15px'}, fontWeight:'500', color: '#31284C', alignSelf:'center', textAlign:'center'}}>
                                    Credit payment settlement through app
                                    </Typography>
                                </Box>
                    </Box>
                    <Box sx={{mb:{xs:3, sm:0}}}>
                            <Box sx={{position:  isTab ? 'unset' : 'absolute',right:  isTab ? 'unset' : '10px',display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center', minWidth:'200px',  maxWidth:{xs:'200px', md:'28%'},   height:'120px', boxShadow:'0px 5px 7px 11px #0000001A', borderRadius:'20px', backgroundColor:'#fff', mt:{xs:'20px',sm:"50px"}, transition:'box-shadow 0.3s ease', '&:hover': {boxShadow:' 0px 20px 20px 5px #0000001A'}}}>
                                <Tracking style={{width:'30px', height:'30px'}}/>
                                <Typography  sx={{alignSelf:'start', fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'14px',sm:'15px',md:'15px'}, fontWeight:'500', color: '#31284C', alignSelf:'center', textAlign:'center'}}>
                                Real time updates or Accurate Tracking
                                </Typography>
                            </Box>
                    </Box>
                </Box>
                <Box sx={{display:'flex', mt: isTab ? 0 : 20, flexDirection: {xs:'column', sm:'row'}, justifyContent:'center', alignItems:'center', gap: {xs:'0', sm:'50px', md:'0'}}}>
                    <Box sx={{mb:{xs:3, sm:0}}}>
                            <Box sx={{position:  isTab ? 'unset' : 'absolute',left:  isTab ? 'unset' : '130px',display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center', minWidth:'200px',  maxWidth:{xs:'200px', md:'28%'},   height:'120px', boxShadow:'0px 5px 7px 11px #0000001A', borderRadius:'20px', backgroundColor:'#fff', mt:{xs:'20px',sm:"50px"}, transition:'box-shadow 0.3s ease', '&:hover': {boxShadow:' 0px 20px 20px 5px #0000001A'}}}>
                                <Reports style={{width:'30px', height:'30px'}}/>
                                <Typography  sx={{alignSelf:'start', fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'14px',sm:'15px',md:'15px'}, fontWeight:'500', color: '#31284C', alignSelf:'center', textAlign:'center'}}>
                                GST Reports Download option
                                </Typography>
                            </Box>
                    </Box>
                    <Box sx={{mb:{xs:3, sm:0}}}>
                            <Box sx={{position:  isTab ? 'unset' : 'absolute',right:  isTab ? 'unset' : '130px',display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center', minWidth:'200px',  maxWidth:{xs:'200px', md:'28%'},   height:'120px', boxShadow:'0px 5px 7px 11px #0000001A', borderRadius:'20px', backgroundColor:'#fff', mt:{xs:'20px',sm:"50px"}, transition:'box-shadow 0.3s ease', '&:hover': {boxShadow:' 0px 20px 20px 5px #0000001A'}}}>
                                <Deliveries style={{width:'30px', height:'30px'}}/>
                                <Typography  sx={{alignSelf:'start', fontFamily:'poppins', lineHeight:'34px', fontSize: {xs:'14px',sm:'15px',md:'15px'}, fontWeight:'500', color: '#31284C', alignSelf:'center', textAlign:'center'}}>
                                Ontime Deliveries
                                </Typography>
                            </Box>
                    </Box>
                </Box>
                </motion.div>
            </Box>
        </Box>
    </motion.div>
  )
}
