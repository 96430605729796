export const navLinks = [
    {
        id:1,
        name:'Features',
        route:'/features',
    },
    {
        id:2,
        name:'Solutions',
        route:'/solutions',
    },
    {
        id:3,
        name:'Get Quote',
        route:'/getquote',
    },
]