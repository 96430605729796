import React, {useState, useEffect} from 'react'
import { Box, Button, Typography, } from '@mui/material';
import { ReactComponent as SliderLogo } from "../../Assets/home/sliderLogo.svg";
import SliderOne from '../../Assets/home/sliderIcon1.png';
import SliderEmpty from '../../Assets/home/sliderEmptyIcon.png';
import { Example } from './Exaample';
import { motion, useAnimation  } from 'framer-motion';
import Carroussel from './Carousel';
import Card from './Card';
import Carousel1 from '../../Assets/home/Carousel1.png';
import Carousel2 from '../../Assets/home/Carousel2.png';
import Carousel3 from '../../Assets/home/Carousel3.png';
import Carousel4 from '../../Assets/home/Carousel4.png';
import AnimationCard from './AnimationCard';



const sliderData = [SliderOne, SliderEmpty, SliderEmpty, SliderEmpty, SliderEmpty, SliderEmpty, SliderEmpty]
let cards = [
    {
      key: 1,
      content: (
        <Card imagen={Carousel1} />
      )
    },
    {
      key: 2,
      content: (
        <Card imagen={Carousel2} />
      )
    },
    {
      key: 3,
      content: (
        <Card imagen={Carousel3} />
      )
    },
    {
      key: 4,
      content: (
        <Card imagen={Carousel4} />
      )
    }
  ];
export default function HeaderSlider() {
    
    return (
      <Box sx={{backgroundColor:'#F0F1F5', py:{xs:2,sm:8},}}>
    <motion.div
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1.5}}
    >
        <Box  sx={{margin:'0 auto',  width:{xs:'100%',sm:'80%'},px:{xs:0,sm:2}, py:{xs:0,sm:2}, border:{xs:'none',sm:'0.63px solid #2B97D1'}, alignSelf:'center', borderRadius:'12.65px', boxShadow: {xs:'none',sm:'0px 6.322751045227051px 6.322751045227051px 6.322751045227051px #2B97D126'}, marginBottom:'30px', backgroundColor:'white'}}>
            <Box sx={{paddingBottom:2, display:{xs:'none', sm:'block'}}}><SliderLogo /></Box>
            <Box sx={{display:'flex',backgroundColor:{xs:'#F0F1F5',sm:'#FFFFFF'}}}>
                <Box  sx={{display:{xs:'none',sm:'flex'}, flexDirection:'column', gap:{xs:'0', sm:'20px'}, paddingRight:'16px',}}>
                    {sliderData.map((item, index) => (
                         <Box
                         key={index}
                         component="img"
                         sx={{ bottom:0, height: '33.51px', width:'33.51px'}}
                         alt="Bsmart"
                         src={item}
                       />
                    ))}
                </Box>
                <Box sx={{flex:1, borderRadius:'25.69px',  background:{xs:'none',sm:'linear-gradient(0deg, #CCCCCC, #CCCCCC), linear-gradient(0deg, #FFFFFF, #FFFFFF)'}}}>
                    <Box  sx={{flex:1,m:1,height:{xs:'200px', sm:'auto'}, backgroundColor:{xs:'#F0F1F5',sm:'#FFFFFF'}, borderRadius:'25.69px',p:{xs:0.5, sm:0.5, md:2}, position:'relative'}}>
                    <Box sx={{display: {xs:'none', sm:'block'}}}>
                        <Example/>
                    </Box>
                    <Box sx={{display: {xs:'block', sm:'none'}, marginTop:'100px'}}>
                        <Carroussel
                            cards={cards}
                            width="30%"
                            margin="0 auto"
                            offset={2}
                            showArrows={false}
                        />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    
     </motion.div>
     </Box>

  )
}
