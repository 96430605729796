import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { motion } from 'framer-motion';

export default function PrivacyDescription() {
  return (
    <motion.div 
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth :"80%", margin:'0 auto', padding:'8px'}}>
        <Box sx={{display:'flex', flexDirection:'column', my:3, gap:'20px'}}>            
            <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'700', color: '#31284C', textAlign:'left'}}>
            Dated
            </Typography>
            <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'700', color: '#31284C', textAlign:'left'}}>
            November 20, 2023
            </Typography>
            <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean consequat massa sed ultrices sodales. Aenean vestibulum feugiat quam et placerat. Nam sit amet neque nulla. Cras id volutpat tortor. Donec cursus ipsum eget sagittis laoreet.</Typography>
            <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left'}}>Sed massa nunc, tempor id eleifend id, eleifend nec lacus. Maecenas iaculis eros quam, in cursus tellus rhoncus a. Integer a turpis sit amet urna venenatis dapibus. Sed elementum interdum mi, nec pretium tortor convallis sed. Suspendisse potenti.</Typography>
            <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left'}}>Nulla sed enim vel dolor convallis faucibus. Nullam fringilla, metus at lacinia porttitor, urna dui interdum risus, eget maximus justo sem eget dui. Phasellus lectus risus, tincidunt at dui ac, ultricies posuere magna. Nullam bibendum maximus condimentum. Sed mi turpis, lobortis sed justo vel, porta mattis erat. Aliquam magna metus, hendrerit at mi in, consequat pulvinar sem. Aenean non sagittis augue. </Typography>
            <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left'}}>Duis in massa eget turpis sagittis mollis. Donec ut leo purus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis viverra arcu non posuere pellentesque. Nullam sed sollicitudin mi.</Typography>
        </Box>
        
    </motion.div>
  )
}
