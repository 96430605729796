import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { motion } from 'framer-motion';

export default function PrivacyHeader() {
  return (
    <motion.div 
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth :"100%", margin:'0 auto', padding:'8px'}}>
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', py:6, gap:'20px', backgroundColor:'#F7F7F7'}}>
            <Box >
                <Typography sx={{textAlign:'center', fontFamily:'poppins', lineHeight:'54px', fontSize: {xs:'25px',sm:'30px',md:'36px'}, fontWeight:'700',  color: '#31284C'}}>Privacy policy</Typography>
            </Box>
            <Box>
                <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'center'}}>
                BSmart privacy policy for the users
                </Typography>
            </Box>
        </Box>
        
    </motion.div>
  )
}
