import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { motion } from 'framer-motion';

export default function HelpHeader() {
  return (
    <motion.div 
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth:'680px', margin:'0 auto', padding:'8px'}}>
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', mt:3, gap:{xs:'10px',md:'20px'}}}>
            <Box >
                <Typography sx={{textAlign:'center', fontFamily:'poppins', lineHeight:'54px', fontSize: {xs:'25px',sm:'30px',md:'36px'}, fontWeight:'700',  color: '#31284C'}}>Frequently Asked Questions</Typography>
            </Box>
            <Box>
                <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'center'}}>
                {/* View Frequently Asked Questions or <Button  variant='text'><span style={{fontWeight:'700', color:'#2B97D1'}}>GET SUPPORT</span></Button> from our executives. */}
                Have some common questions?
                </Typography>
                <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'center'}}>
                Find them here
                </Typography>
            </Box>
        </Box>
        
    </motion.div>
  )
}










//backup for FAQ

// <motion.div 
// initial={{opacity:0,scale:0.5}}
// animate={{opacity:1,scale:1}}
// // exit={{opacity:0}}
// transition={{delay:0.3, duration: 1}}
// style={{maxWidth:'680px', margin:'0 auto', padding:'8px'}}>
//     <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', mt:3, gap:{xs:'10px',md:'20px'}}}>
//         <Box >
//             <Typography sx={{textAlign:'center', fontFamily:'poppins', lineHeight:'54px', fontSize: {xs:'25px',sm:'30px',md:'36px'}, fontWeight:'700',  color: '#31284C'}}>Frequently Asked Questions</Typography>
//         </Box>
//         <Box>
//             <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'center'}}>
//             Have some common questions?
//             </Typography>
//             <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'center'}}>
//             Find them here
//             </Typography>
//         </Box>
//     </Box>
    
// </motion.div>