import React, {useEffect, useState} from 'react'
import { Box, Button, IconButton, Typography, Drawer  } from '@mui/material';
import logo from '../Assets/logo.png';
import {FaBars} from 'react-icons/fa';
import {IoMdClose} from 'react-icons/io';
import { NavLink, useNavigate } from 'react-router-dom';
import { navLinks } from './navData';
import { motion, AnimatePresence } from 'framer-motion';
import { makeStyles } from '@mui/styles';


export const useStyles = makeStyles((theme) => ({
    // Media query for screens narrower than 1024px
    paperRoot: {
     '& .MuiPaper-root': {
      width:'40%'
     }
    },
    '@media (max-width: 450px)': {
      paperRoot: {
        '& .MuiPaper-root': {
         width:'70%'
        },

  },
  },


}));


export default function Navbar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [SideBar, setSideBar] = useState(false);
  const openDrawer = () => {
    setSideBar(true)
  }
  
  const handleResize = ()=> {
    if(window.innerWidth > 899) {
      setSideBar(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return()=> {
      window.removeEventListener('resize',handleResize)
    }
  },[])
  const Navdrawer = () => {
    useEffect(() => {

    },[])

    const navlinkClicked = () => {
      console.log('click');
      setSideBar(false)
    }
    return(
      <AnimatePresence>
        {SideBar && (
          <motion.Box
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'spring', duration: 0.5 }}
            className={classes.paperRoot}
          >
             <Drawer anchor='right' open={SideBar} onClose={() => setSideBar(false)}  className={classes.paperRoot} >
            <motion.Box
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ type: 'spring', duration: 0.5 }}
              className={classes.paperRoot}
            sx={{
              zIndex: 10,
              height: 'inherit',
              width: { xs: '340px', sm: '300px' },
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
          
              <Box sx={{ display: 'grid', placeItems: 'flex-end' }}>
                <IconButton onClick={() => setSideBar(false)}>
                  <IoMdClose
                    style={{
                      height:'25px',
                      width:'25px',
                      color: '#2B97D1',
                      fontSize: '36px',
                    }}
                  />
                </IconButton>
              </Box>

            <Box sx={{ p: 1 }}>
              {navLinks.map((item, index) => (
                <NavLink
                  key={index}
                  to={item.route}
                  style={{ textDecoration: 'none', color: 'none', }}
                  onClick={navlinkClicked}
                >
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      color: '#31284C',
                      p: 2,
                      pr: 2,
                      '&:hover': {
                        background: 'lightblue',
                        color: 'black',
                      },
                      borderRadius: '4px',
                    }}
                  >
                    {item.name}
                  </Typography>
                </NavLink>
              ))}
            </Box>
            <Box sx={{display:'flex', gap:'15px', flexDirection:'column', width:'80%', p:1}}>
              <Button variant="outlined" sx={{padding:'10px, 16px, 10px, 16px', borderRadius:'6px'}}><Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize:'16px', fontWeight:'500', textTransform:'none', color: '#31284C',p:1}}>Login</Typography></Button>
              <Button variant="contained" 
              sx={{padding:'10px, 16px, 10px, 16px', borderRadius:'6px',backgroundColor:'#2B97D1', 
              '&:hover': {
                backgroundColor:'#2B97D1'
              },
            }}
              ><Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize:'16px', fontWeight:'700',  textTransform:'none', p:1,}}>Book a demo</Typography></Button>
          </Box>
          </motion.Box>
        </Drawer>
          </motion.Box>)}
       
      </AnimatePresence>
    )
  }
  return (
  <Box sx={{width:'100%',position: 'sticky', top: 0, zIndex: 10}}>
  <Navdrawer/>
    <Box sx={{backgroundColor:'#fff', boxShadow:'0px 4px 10px 0px #00000026'}}>
      <Box sx={{minWidth:'90%', maxWidth:'90%', display:'flex', alignItems:'center', justifyContent:'space-between', padding:'1rem', margin:'0 auto'}}>
        <Box className='nav-header' sx={{display: 'flex', alignItems:'center', justifyContent: {xs:'space-between', md:'start'}, width: {xs:'100%', md:'auto'}}}>
            <NavLink to={'/'}> 
            <Box
              component="img"
              sx={{ bottom:0, height: {xs:32,sm:40,md:42}, width: {xs:130,sm:170,md:180},}}
              alt="Bsmart"
              src={logo}
            />
            </NavLink>
            <IconButton onClick={openDrawer}  className={classes.toggleButton} sx={{
              display: {xs:'block', sm: 'block', md: "none"},padding:'8px'
            }}>
                <FaBars />
            </IconButton>
        </Box>

        <Box className={{}} sx={{display: {xs:'none',sm: 'none', md: "flex"}, gap:'40px'}}>
          {navLinks.map((item, index) => (
                  <NavLink 
                  key={index}
                  to={item.route}
                  sx={{  '&:hover': {
                    color:'black',
                    fontWeight:'600'
                  },}}
                  style={{
                    textDecoration: 'none', position: 'relative',
                  }}
              >
                <Typography sx={{fontFamily:'poppins',  color: '#31284C', lineHeight:'21px', fontSize:'14px', fontWeight:'500', '&:hover': {color:'black', fontWeight:'600'}}}>{item.name}</Typography>
              </NavLink>
          ))}
    
        </Box>

        <Box className={{}} sx={{display: {xs: 'none',sm: 'none', md: "flex"}, gap:'15px'}}>
          <Button variant="outlined" sx={{padding:'10px, 16px, 10px, 16px', borderRadius:'6px'}}><Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize:'16px', fontWeight:'500', textTransform:'none', color: '#31284C',}}>Login</Typography></Button>
          <Button variant="contained" 
          sx={{padding:'10px, 16px, 10px, 16px', borderRadius:'6px', backgroundColor:'#2B97D1', 
          '&:hover': {
            backgroundColor:'#2B97D1'
          },
        }}
          ><Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize:'16px', fontWeight:'700',  textTransform:'none'}}>Book a demo</Typography></Button>
        </Box>
      </Box>
      
    </Box>
    </Box>
  )
}
