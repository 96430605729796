import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { ReactComponent as WatchVideo } from "../../Assets/home/WatchVideoIcon.svg";
import { ReactComponent as MobileWatchVideo } from "../../Assets/home/MobileWatchVideo.svg";
import Image1 from '../../Assets/Aboutus/about.jpg';
import mission from '../../Assets/Aboutus/mission.jpg';
import vision from '../../Assets/Aboutus/vision.jpg';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Vision() {
  const [isScalingUp, setIsScalingUp] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsScalingUp((prev) => !prev);
    }, 600);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    return () => clearInterval(intervalId);
  }, []);
  return (
    <motion.div 
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth:'85%', margin:'0 auto', padding:'8px'}}>
      <Box sx={{display:'flex', gap:'10px', width:'100%', flexDirection:{xs:'column',md:'row'}}}>
        
      <Box sx={{width:{xs:'100%',md:'50%'}}}>
              <LazyLoadImage 
              // component='img'
                src={vision}
                effect='blur'
                style={{width:'100%'}}
              />
        </Box>

        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'start', mt:3, gap:'20px', width:{xs:'100%',md:'50%'}}}>
          <Box  sx={{display:'flex', flexDirection:'column'}}>
          <Typography sx={{textAlign:'left', fontFamily:'poppins', lineHeight:'54px', fontSize: {xs:'25px',sm:'30px',md:'36px'}, fontWeight:'800',  color: '#31284C'}}>Vision</Typography>
          </Box>
          <Box>
          <Typography sx={{mb:1,fontFamily:'poppins', lineHeight:'31px', letterSpacing:'0.02em', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left',}}>
          "To pioneer cloud-based business management that consistently integrates customer, sales and driver apps, revolutionizing the way businesses manage stock while engaging drivers and delighting customers. We envision a future where real-time insights, intuitive interfaces, and smart functionalities simplify inventory processes, enhance operational efficiency, and elevate the entire business experience in smart way."
            </Typography>
        
          </Box>

        </Box> 


      </Box>


    <Box sx={{mt:3,display:'flex', gap:'10px', width:'100%', flexDirection:{xs:'column',md:'row'}}}>
        <Box sx={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'start', mt:3, gap:'20px', width:{xs:'100%',md:'50%'}}}>
          <Box  sx={{display:'flex', flexDirection:'column'}}>
          <Typography sx={{textAlign:'left', fontFamily:'poppins', lineHeight:'54px', fontSize: {xs:'25px',sm:'30px',md:'36px'}, fontWeight:'800',  color: '#31284C'}}>Mission</Typography>
          </Box>
          <Box>
          <Typography sx={{mb:1,fontFamily:'poppins', lineHeight:'31px', letterSpacing:'0.02em', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left',}}>
          Our mission is to empower businesses to optimize inventory processes, enhance operational efficiency, and deliver exceptional customer experiences, while enabling drivers to operate with precision and ease.
            </Typography>
        
          </Box>

        </Box> 

        <Box sx={{width:{xs:'100%',md:'50%'}}}>
              <LazyLoadImage 
              // component='img'
              effect='blur'
                src={mission}
                style={{width:'100%'}}
              />
        </Box>
      </Box>
      

    </motion.div>
  )
}
