import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
    customDotList: {
     maxWidth:'400px',
     maxHeight:'800px',
    },
  
    '@media (max-width: 450px)': {
    customDotList: {
        maxWidth: '300px',
      },
    },
  
  }));
function Card({ imagen }) {
  const classes = useStyles();
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(5.03)" : "scale(1)"
  });
  return (
    <animated.div
      style={{props3, display:'flex', justifyContent:'center',  width: "15rem", }}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <Box component='img' src={imagen} alt="" sx={{ margin:'0 auto'}} className={classes.customDotList}/>
    </animated.div>
  );
}

export default Card;
