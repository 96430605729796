import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { ReactComponent as Troubleshooting} from '../../Assets/Support/Troubleshooting.svg';
import { ReactComponent as Customisation} from '../../Assets/Support/Customisation.svg';
import { ReactComponent as Difficulties} from '../../Assets/Support/Difficulties.svg';
import { ReactComponent as SupportIcon} from '../../Assets/Support/SupportIcon.svg';
import { ReactComponent as Arrow} from '../../Assets/Support/Arrow.svg';

export default function SupportMain() {
  return (
    <motion.div 
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth :"80%", margin:'0 auto', padding:'8px'}}>
    <>
        <Box sx={{display:'flex', flexDirection:{xs:'column',sm:'row'}, my:3, gap:{xs:'40px',sm:'20px'}, justifyContent:{xs:'center',sm:'space-around'}, }}>            
            <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                <Box sx={{alignSelf:'center'}}>
                    <Troubleshooting/>
                </Box>
                <Typography sx={{alignSelf:'center',fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'600', color: '#31284C', textAlign:'left'}}>
                Troubleshooting
                </Typography>
            </Box>
            <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                <Box sx={{alignSelf:'center'}}>
                    <Customisation/>
                </Box>
                <Typography sx={{alignSelf:'center',fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'600', color: '#31284C', textAlign:'left'}}>
                Customization
                </Typography>
            </Box>
            <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                <Box sx={{alignSelf:'center'}}>
                    <Difficulties/>
                </Box>
                <Typography sx={{alignSelf:'center',fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'600', color: '#31284C', textAlign:'left'}}>
                Difficulties
                </Typography>
            </Box>
        </Box>
        <Box sx={{mt:12, mb:12}}>
            <Box sx={{p: "20px 24px", border:'0.02px solid #b5b5b6', borderRadius:'20px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'14px',sm:'16px',md:'17px'}, fontWeight:'600', color: '#333333', textAlign:'left'}}>
                    We’re always here to help
                </Typography>
                    <Button variant='text' color="secondary" startIcon={<SupportIcon />} endIcon={<Arrow />}>
                        <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'14px',sm:'16px',md:'17px'}, fontWeight:'600', }}>
                        Get Support
                        </Typography>
                    </Button>
                </Box>

        </Box>
    </>
        
    </motion.div>
  )
}
