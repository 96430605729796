import React, {useState, useEffect} from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { ReactComponent as Date1} from '../../Assets/Product/ViewExpenses/Date1.svg';
import { ReactComponent as Date2} from '../../Assets/Product/ViewExpenses/Date2.svg';
import { ReactComponent as Date3} from '../../Assets/Product/ViewExpenses/Date3.svg';
import { ReactComponent as Bar} from '../../Assets/Product/ViewExpenses/Bar.svg';
import { ReactComponent as Bar2} from '../../Assets/Product/ViewExpenses/Bar2.svg';
import { ReactComponent as Bar3} from '../../Assets/Product/ViewExpenses/Bar3.svg'
import { ReactComponent as Bar4} from '../../Assets/Product/ViewExpenses/Bar4.svg';
import { ReactComponent as Greenround} from '../../Assets/Product/ViewExpenses/Greenround.svg';
import { ReactComponent as Orangeround} from '../../Assets/Product/ViewExpenses/Orangeround.svg';
import { ReactComponent as Yellowround} from '../../Assets/Product/ViewExpenses/Yellowround.svg';
import { useSpring, animated } from 'react-spring';


export default function ViewExpenses() {
    const expenses = [{name:'Inventory Expense', value: '$54,650', icon:<Greenround/>},{name:'Vehicle Expense', value: '$12,650', icon: <Orangeround/>}, {name:'Driver Expense', value: '$4650', icon: <Yellowround/>}]
    const CurveCharts = [<Bar  style={{ width:'200px', height:'200px'}}/>, <Bar2  style={{ width:'200px', height:'200px'}}/>, <Bar3  style={{ width:'200px', height:'200px'}}/>, <Bar4  style={{ width:'200px', height:'200px'}}/>]
    
    const AnimatedNumber = ({ from, to, resetValue, duration }) => {
        const [value, setValue] = useState(from);
      
        const animatedProps = useSpring({
          from: { number: from },
          to: async (next) => {
            while (true) {
              await next({ number: to, reset: true });
              await next({ number: resetValue, reset: false });
            }
          },
          reset: true,
          config: { duration },
          onChange: ({ number, reset }) => {
            if (reset) {
              setValue(resetValue);
            } else {
              setValue(Math.round(number));
            }
          },
        });
      
        return <animated.span>{animatedProps.number.interpolate((num) => num.toFixed(0))}</animated.span>;
      };

      const AnimatedExpenseBar = () => {
        const [currentIconIndex, setCurrentIconIndex] = useState(0);
      
        useEffect(() => {
          const intervalId = setInterval(() => {
            setCurrentIconIndex((prevIndex) => (prevIndex + 1) % CurveCharts.length);
          }, 1000);
      
          return () => {
            clearInterval(intervalId);
          };
        }, []);
      
        return CurveCharts[currentIconIndex]
      };


  return (
    <motion.div 
    initial={{opacity:0,scale:0.5}}
    animate={{opacity:1,scale:1}}
    // exit={{opacity:0}}
    transition={{delay:0.3, duration: 1}}
    style={{maxWidth :"100%", margin:'30px auto 0 auto', padding:'30px 8px 30px 8px', backgroundColor:'#F7F7F7'}}>
        <Box sx={{maxWidth:'90%', margin:'0 auto'}}>
            <Box sx={{display:'flex', width:'100%', gap:'20px',my:3, flexDirection:{xs:'column', md:'row'} }}
            >            
                <Box sx={{width:{xs:'100%',md:'60%'}, display:'flex', justifyContent:'start'}}>
                    <Box sx={{width:{xs:'100%', md:'80%'}, display:'flex', flexDirection:'column', gap:'30px', border:'0.48px solid #CCCCCC', backgroundColor:'#fff', borderRadius:'20px', padding:'16px 8px'}}>
                        <Box sx={{display:'flex', justifyContent:'space-between'}}> 
                            <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left'}}>
                            Expenses
                            </Typography>
                            <Box sx={{display:'flex', gap:'5px'}}>
                                <Date1/>
                                <Date2/>
                                <Date3/>
                            </Box>
                        </Box>
                        <Box sx={{display:'flex', gap:'20px', flexDirection:{xs:'column', sm:'row'},}}>
                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                {/* <Bar4 style={{width:'300px', height:'200px'}}/> */}
                                <AnimatedExpenseBar />
                            </Box>
                            <Box sx={{display:'flex', gap:'10px',  flexWrap:'wrap', justifyContent:'center', alignItems:'center'}}>
                                
                                   {expenses.map((item, index) => (
                                    <Box  key={index} sx={{display:'flex', flexDirection:'column', width:'50%', flexWrap:'wrap', borderRadius:'10px', boxShadow:' 0px 10.939230918884277px 38.04949951171875px 0px #00000012', height:'80px', width:'100px', padding:'10px'}}>
                                        <Box sx={{display:'flex', gap:'5px', alignItems:'center'}}>
                                            {item.icon}
                                            <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'12px',sm:'13px',md:'13px'}, fontWeight:'400', color: '#858585', textAlign:'left'}}>
                                            {item.name}
                                            </Typography>
                                        </Box>
                                            <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'12px',sm:'14px',md:'14px'}, fontWeight:'700', color: '#050505', textAlign:'left'}}>
                                            {/* {item.value} */}
                                            $<AnimatedNumber from={12000} to={12500} resetValue={12000} duration={15000} />
                                            </Typography>
                                       
                                    </Box>  
                                   ))}
                            </Box>
                        </Box>
                    </Box>

                </Box>
                <Box sx={{width:{xs:'100%',md:'40%'}, display:'flex', alignItems:'center'}}>
                    <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap:{xs:'20px',sm:'30px'}}}>
                        <Typography sx={{fontFamily:'poppins', lineHeight:'24px', letterSpacing:'0.2em', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left'}}>
                        VIEW INSIGHTS
                        </Typography>
                        <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'22px',sm:'26px',md:'30px'}, fontWeight:'700', color: '#000000', textAlign:'left'}}>
                        View Business Expenses
                        </Typography>
                        <Typography sx={{fontFamily:'poppins', lineHeight:'24px', fontSize: {xs:'13px',sm:'15px',md:'16px'}, fontWeight:'400', color: '#31284C', textAlign:'left'}}>
                        Easy to use software with AI-Powered success management platform that helps your business.
                        </Typography>
                    </Box>
                </Box>
            </Box>  
        </Box>   
        
    </motion.div>
  )
}
