import React, {useEffect} from 'react';
import { BrowserRouter, Route, Routes, useLocation  } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Features from './pages/Feature';
import Solutions from './pages/Solutions';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Help from './pages/Help';
import Aboutus from './pages/Aboutus';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Support from './pages/Support';
import Footer from './components/Footer';
import ScrollToTopOnRouteChange from './components/ScrollToTop';
import { motion, AnimatePresence } from 'framer-motion';
import Product from './pages/Product';
import './App.css';

function App() {
  const location = useLocation();  
  return (
    <>
      <Navbar />
      <ScrollToTopOnRouteChange />
      <AnimatePresence>
      <Routes >
        <Route path='/' element={<Home/>} />
        <Route path='/features' element={<Features/>} />
        <Route path='/solutions' element={<Solutions/>} />
        <Route path='/getquote' element={<Pricing/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/faq' element={<Help/>} />
        <Route path='/aboutus' element={<Aboutus/>} />
        <Route path='/privacy' element={<PrivacyPolicy/>} />
        <Route path='/support' element={<Support/>} />
        <Route path='/product' element={<Product/>} />

      </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
